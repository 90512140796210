import SVA from '@pkgs/shared-client/components/SVA';
import SVLink from '@pkgs/shared-client/components/SVLink';
import clsx from 'clsx';

const SVLandingPageFooter = ({ className }: { className?: string }) => {
	return (
		<div
			className={clsx(
				'type-small -smp:flex-col-reverse -smp:items-center flex items-end justify-between uppercase',
				className,
			)}
		>
			<span className="-smp:py-10">
				<SVA
					href="https://inspire.savee.it/the-story-behind-how-we-built-savee/"
					target="_blank"
					rel="noopener noreferrer"
				>
					Made with love by immigrants
				</SVA>
			</span>
			<span className="flex flex-wrap justify-center space-x-10 -smp:space-x-2">
				<SVA
					href="https://justsavee.com/"
					title="Mobile App"
					target="_blank"
					rel="noopener noreferrer"
				>
					Mobile App
				</SVA>
				<SVA Component={SVLink} to="/extension/" title="Extension">
					Extension
				</SVA>
				<SVA
					href="https://inspire.savee.it/"
					title="Blog"
					target="_blank"
					rel="noopener noreferrer"
				>
					Blog
				</SVA>
				<SVA href="/course/" title="Course">
					Course
				</SVA>
				<SVA
					href="https://marketplace.savee.it/"
					title="Marketplace"
					target="_blank"
					rel="noopener noreferrer"
				>
					Marketplace
				</SVA>
				<SVA Component={SVLink} to="/site-maker/" title="Site Maker">
					Create your site
				</SVA>
				<SVA Component={SVLink} to="/team/" title="Team">
					For teams
				</SVA>
				<SVA Component={SVLink} to="/terms/" title="Terms">
					Terms
				</SVA>
				<SVA Component={SVLink} to="/contact-us/" title="Contact">
					Contact us
				</SVA>
			</span>
		</div>
	);
};

export default SVLandingPageFooter;
