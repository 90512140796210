import SVGridContainer from '@apps/www/src/www/containers/SVGridContainer';
import SVWithUserPage, {
	type Props as SVWithUserPageProps,
} from '@apps/www/src/www/containers/SVWithUserPage';
import usePaginatedQuery from '@apps/www/src/www/hooks/usePaginatedQuery';
import usePersistentSetting, {
	PersistentSettingKeys,
} from '@apps/www/src/www/hooks/usePersistentSetting';
import UserItemsQuery from '@apps/www/src/www/queries/UserItemsQuery';
import SVGrid from '@pkgs/shared-client/components/SVGrid';

const _SVFeedUserItemsPage = ({ user }: SVWithUserPageProps) => {
	const [sortMethod] = usePersistentSetting(PersistentSettingKeys.ITEMS_SORT_METHOD);

	const { items, loading, paginate } = usePaginatedQuery(UserItemsQuery, {
		variables: { username: user.username, sortMethod },
	});

	return (
		<SVGridContainer
			items={items}
			isLoading={loading}
			onPaginate={paginate}
			sourceType={SVGrid.SOURCE_TYPES.USER}
		/>
	);
};

const SVFeedUserItemsPage = SVWithUserPage(_SVFeedUserItemsPage);

// @ts-ignore fix this
SVFeedUserItemsPage.withUserHeader = true;

export default SVFeedUserItemsPage;
