import { useFragment } from '@apollo/client';
import { ItemFragmentFragment, UserFragmentFragment } from '@apps/www/src/__generated__/graphql';
import SVMoreOptionsContent from '@apps/www/src/www/containers/SVMoreOptionsContent';
import SVWithTooltipErrorBoundary, {
	type Props as SVWithTooltipErrorBoundaryProps,
} from '@apps/www/src/www/containers/SVWithTooltipErrorBoundary';
import useAllowedBoardsToSave from '@apps/www/src/www/hooks/useAllowedBoardsToSave';
import useAllowedFeatureCheckEvent from '@apps/www/src/www/hooks/useAllowedFeatureCheckEvent';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import useSaveItem from '@apps/www/src/www/hooks/useSaveItem';
import SVNewBoardModal from '@apps/www/src/www/modals/SVNewBoardModal';
import ItemFragment from '@apps/www/src/www/queries/fragments/ItemFragment';
import { toggleItemSort } from '@apps/www/src/www/reducers/grid';
import SVButton from '@pkgs/shared-client/components/SVButton';
import SVDropdown from '@pkgs/shared-client/components/SVDropdown';
import SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import type { ItemPassthroughProps } from '@pkgs/shared-client/components/SVGrid';
import SVIconButton from '@pkgs/shared-client/components/SVIconButton';
import SVModal from '@pkgs/shared-client/components/SVModal';
import { emptyPreventDefault } from '@pkgs/shared-client/helpers/dom';
import plural from '@pkgs/shared-client/helpers/plural';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import IconChevronDownSVG from '@pkgs/shared-client/img/icon-chevron-down-inlined.svg';
import IconMoreSVG from '@pkgs/shared-client/img/icon-more-inlined.svg';
import IconMoveSVG from '@pkgs/shared-client/img/icon-move-inlined.svg';
import { type GridHoverOverlayUISize } from '@pkgs/shared/constants';
import AllowedFeature from '@pkgs/shared/enums/AllowedFeature';
import BoardOwnershipType from '@pkgs/shared/enums/BoardOwnershipType';
import BoardUserRole from '@pkgs/shared/enums/BoardUserRole';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

const UI_SIZE_CLASSES = {
	large: {
		padding: 'p-[14px]',
		font: 'text-base',
		icon: 'w-[19px] h-[19px] min-w-[19px] min-h-[19px]',
	},
	medium: {
		padding: 'p-3',
		font: 'text-sm',
		icon: 'w-[17px] h-[17px] min-w-[17px] min-h-[17px]',
	},
	small: {
		padding: 'p-[10px]',
		font: 'text-xs',
		icon: 'w-[15px] h-[15px] min-w-[15px] min-h-[15px]',
	},
};

function getAddToBoardLabel(
	items: ItemFragmentFragment[],
	boards: ArrayElement<UserFragmentFragment['boards']>[],
	uiSize: GridHoverOverlayUISize,
) {
	if (items.length === 0) {
		return null;
	}

	const item = items[0];

	if (item.asset.ownBoards?.length === 0) {
		return uiSize === 'small' ? 'Board' : 'Add to Board';
	}

	if (item.asset.ownBoards?.length === 1) {
		const boardID = item.asset.ownBoards[0]._id;
		const board = boards.find((board) => board._id === boardID);

		if (board) {
			return board.name;
		}
	}

	return plural(item.asset.ownBoards?.length, 'Board');
}

const _SaveButtonsContainerInner = ({
	items,
	uiSize,
	showTooltipError,
	role,
}: {
	items: ItemFragmentFragment[];
	uiSize: GridHoverOverlayUISize;
	role: ItemPassthroughProps['role'];
} & SVWithTooltipErrorBoundaryProps) => {
	const { isSaved, isLoading, itemsCount, toggleSave } = useSaveItem({
		items,
		showTooltipError,
	});
	const isLoggedIn = useIsLoggedIn();
	const boards = useAllowedBoardsToSave(items);
	const addToBoardLabel = getAddToBoardLabel(items, boards, uiSize);

	const showSaveButton = useMemo(() => {
		return !items.some((item) => item.isOwner) && role !== BoardUserRole.OWNER;
	}, [items, role]);

	const handleClick = useEventCallback((event: React.UIEvent) => {
		toggleSave();

		if (event) {
			event.preventDefault();
			event.stopPropagation();
			return false;
		}
	});

	const handleSaveToBoard = useEventCallback(
		(board: ArrayElement<UserFragmentFragment['boards']>) => {
			toggleSave(board._id);
		},
	);

	const handleSaveToNewBoard = useAllowedFeatureCheckEvent(AllowedFeature.ADD_BOARD, () => {
		SVModal.open(SVNewBoardModal, {
			items,
			ownershipType: BoardOwnershipType.USER,
		});
	});

	if (!isLoggedIn) {
		return null;
	}

	return (
		<div className="absolute bottom-0 right-0 flex items-center justify-between w-full max-w-full space-x-2">
			<div className="flex items-center min-w-0" onClick={emptyPreventDefault}>
				<SVDropdown
					triggerType={SVDropdown.TRIGGER_TYPES.CLICK}
					className="max-w-full"
					renderTrigger={({ isOpen: _, ...props }) => {
						return (
							<button
								className="flex min-w-0 max-w-full items-center space-x-0.5 py-2 text-white"
								data-testid="item-save-dropdown-trigger"
								{...props}
							>
								<span
									className={clsx(
										'truncate font-medium',
										UI_SIZE_CLASSES[uiSize].font,
									)}
								>
									{addToBoardLabel}
								</span>
								<span className="mt-0.5">
									<IconChevronDownSVG className="w-4 h-4" />
								</span>
							</button>
						);
					}}
					renderContent={() => (
						<SVDropdownContent.Boards
							boards={boards}
							onNewBoard={handleSaveToNewBoard}
							onBoardClick={handleSaveToBoard}
							keepOpenOnClick={true}
							selectedIDs={items.flatMap((item) => item.asset.ownBoards.map((board) => board._id))}
						/>
					)}
					maxHeight={520}
				/>
			</div>

			{showSaveButton ? (
				<SVButton
					onClick={handleClick}
					isLoading={isLoading}
					title={plural(itemsCount, 'Save')}
					className={clsx(
						'duration-over w-fit rounded-full px-3 py-2 font-medium transition-all',
						isSaved
							? 'bg-white text-black hover:bg-white hover:opacity-80'
							: 'bg-gray-900 bg-opacity-30 text-white backdrop-blur-lg hover:bg-gray-900 hover:bg-opacity-40',
						UI_SIZE_CLASSES[uiSize].font,
					)}
				>
					{isSaved ? 'Saved' : 'Save'}
				</SVButton>
			) : null}
		</div>
	);
};

const _SaveButtonsContainer = SVWithTooltipErrorBoundary(_SaveButtonsContainerInner);

const _MoreOptionsButtonContainer = ({
	items,
	uiSize,
}: {
	items: ItemFragmentFragment[];
	uiSize: GridHoverOverlayUISize;
}) => {
	const isLoggedIn = useIsLoggedIn();

	if (!isLoggedIn) {
		return null;
	}

	return (
		<SVDropdown
			triggerType={SVDropdown.TRIGGER_TYPES.CLICK}
			renderTrigger={({ isOpen: _, ...props }) => (
				<SVIconButton
					className="text-white hover:text-white"
					iconClassName={UI_SIZE_CLASSES[uiSize].icon}
					src={IconMoreSVG}
					title="More Options"
					{...props}
				/>
			)}
			renderContent={(props) => <SVMoreOptionsContent items={items} {...props} />}
		/>
	);
};

const _MoveButton = ({
	item,
	isSortLoading,
	uiSize,
}: {
	item: ItemFragmentFragment;
	isSortLoading: boolean;
	uiSize: GridHoverOverlayUISize;
}) => {
	const dispatch = useDispatch();

	const handleItemMove = useEventCallback(() => {
		dispatch(toggleItemSort(item._id, true));
	});

	return (
		<SVIconButton
			className={clsx(
				'duration-over hover:text-primary transition-opacity ease-out hover:opacity-60',
				isSortLoading && 'pointer-events-none opacity-50',
			)}
			iconClassName={clsx('text-white', UI_SIZE_CLASSES[uiSize].icon)}
			src={IconMoveSVG}
			onMouseDown={handleItemMove}
		/>
	);
};

const SVGridItemHoverOverlayContainer = ({
	item: itemProp,
	uiSize,
	isSortLoading,
	canMove,
	role,
}: {
	item: ItemFragmentFragment;
	uiSize: GridHoverOverlayUISize;
	isSortLoading: boolean;
	canMove: boolean;
	role: ItemPassthroughProps['role'];
}) => {
	// TODO: Can we remove this and adjust on SVBaseGridContainer to always re-reference the renderHoverOverlay prop whenever `item` changes?
	// We watch the fragment here to get updates from apollo cache, otherwise the `SVGridItem` won't send updates
	// whenever `asset.ownBoards` changes, for example.
	const { data: itemFragment, complete } = useFragment<ItemFragmentFragment>({
		fragment: ItemFragment,
		from: {
			__typename: 'Item',
			_id: itemProp._id,
		},
	});

	const item = complete ? itemFragment : itemProp;

	const items = useMemo(() => [item], [item]);

	return (
		<div className="absolute top-0 left-0 w-full h-full grid-hide-on-editing theme-dark">
			<div className="absolute w-full h-full bg-black bg-opacity-50" />
			<div className={clsx('absolute flex h-full w-full', UI_SIZE_CLASSES[uiSize].padding)}>
				<div className="relative flex-1">
					{canMove ? (
						<div className="absolute top-0 left-0" onClick={emptyPreventDefault}>
							<_MoveButton
								item={item}
								isSortLoading={isSortLoading}
								uiSize={uiSize}
							/>
						</div>
					) : null}
					<div className="absolute top-0 right-0" onClick={emptyPreventDefault}>
						<_MoreOptionsButtonContainer items={items} uiSize={uiSize} />
					</div>
					<_SaveButtonsContainer items={items} uiSize={uiSize} role={role} />
				</div>
			</div>
		</div>
	);
};

export default SVGridItemHoverOverlayContainer;
