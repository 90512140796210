import usePersistentSetting, {
	PersistentSettingKeys,
} from '@apps/www/src/www/hooks/usePersistentSetting';
import useUIState, { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import SVDropdown from '@pkgs/shared-client/components/SVDropdown';
import SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import SVIconButton from '@pkgs/shared-client/components/SVIconButton';
import IconSortSVG from '@pkgs/shared-client/img/icon-sort-inlined.svg';
import ItemsSortMethod from '@pkgs/shared/enums/ItemsSortMethod';

const SORT_METHODS = [
	{
		name: ItemsSortMethod.CUSTOM,
		label: 'Custom',
	},
	{
		name: ItemsSortMethod.NEWEST_FIRST,
		label: 'Newest first',
	},
	{
		name: ItemsSortMethod.OLDEST_FIRST,
		label: 'Oldest first',
	},
];

const SVGridSortButtonContainer = () => {
	const [sortMethod, setSortMethod] = usePersistentSetting(
		PersistentSettingKeys.ITEMS_SORT_METHOD,
	);

	const [showGridControls] = useUIState(UIStateKeys.SHOW_GRID_CONTROLS);

	// Don't show up if page is not an items grid
	if (!showGridControls) {
		// To keep profile page navigation in the middle
		return <div className="w-5" />;
	}

	return (
		<SVDropdown
			triggerType={SVDropdown.TRIGGER_TYPES.CLICK}
			renderTrigger={({ isOpen: _, ...props }) => (
				<SVIconButton src={IconSortSVG} title="Sort saves" {...props} />
			)}
			renderContent={() => (
				<SVDropdownContent.Links>
					<SVDropdownContent.Links.ItemLabel>Sort by</SVDropdownContent.Links.ItemLabel>
					{SORT_METHODS.map((sortMethodInfo) => (
						<SVDropdownContent.Links.Item
							isSelected={sortMethod === sortMethodInfo.name}
							key={sortMethodInfo.name}
							onClick={() => setSortMethod(sortMethodInfo.name)}
						>
							{sortMethodInfo.label}
						</SVDropdownContent.Links.Item>
					))}
				</SVDropdownContent.Links>
			)}
		/>
	);
};

export default SVGridSortButtonContainer;
