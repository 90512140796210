import useScrollAnimation from '@apps/www/src/www/hooks/useScrollAnimation';
import SVMinimalVideoPlayer from '@pkgs/shared-client/components/SVMinimalVideoPlayer';
import SVPageTitle from '@pkgs/shared-client/components/SVPageTitle';
import { useRef } from 'react';

const _MovieSection = ({
	movie,
	minimalVideoPlayer,
}: {
	movie: string;
	minimalVideoPlayer: boolean;
}) => {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const videoRef = useRef<HTMLDivElement | null>(null);

	useScrollAnimation({
		containerRef,
		update: (progress, { windowHeight }) => {
			if (videoRef.current) {
				const movement = Math.min(100, (windowHeight / 900) * 100);

				videoRef.current.style.transform = `translateY(${progress * movement}px)`;
			}
		},
		enableAnimationAlways: true,
	});

	return (
		<div
			className="-md:mt-10 -sm:pb-[70%] -sm:h-[400px] -lg:h-[680px] relative inline-block h-[800px] w-full pb-[60%]"
			ref={containerRef}
		>
			<div ref={videoRef} className="absolute inset-0 overflow-hidden rounded-xl">
				{minimalVideoPlayer ? (
					<SVMinimalVideoPlayer
						src={movie}
						autoPlay
						muted
						playsInline
						loop
						className="absolute inset-0 object-cover w-full h-full"
					/>
				) : (
					<video
						src={movie}
						className="absolute inset-0 object-cover w-full h-full"
						controls={false}
						autoPlay
						muted
						playsInline
						loop
					/>
				)}
			</div>
		</div>
	);
};

const SVInfoSection = ({
	CTAButton,
	title,
	description,
	descriptionExtraLine,
	label,
	movie,
	minimalVideoPlayer = false,
}: {
	CTAButton: React.ReactNode;
	title: string;
	description: string;
	descriptionExtraLine: string;
	label: string;
	movie: string;
	minimalVideoPlayer?: boolean;
}) => (
	<div className="flex items-center justify-center w-full max-w-screen-xl mx-auto my-32 space-x-32 -md:flex-col-reverse -md:space-y-10 -md:px-6 -md:space-x-0 -lg:space-x-10 -sm:my-24">
		<_MovieSection movie={movie} minimalVideoPlayer={minimalVideoPlayer} />
		<div className="w-full flex-center">
			<div className="-md:text-center -md:max-w-lg flex max-w-[450px] flex-col text-start">
				<div className="text-gray-700 type-subnav">{label}</div>
				<SVPageTitle className="mt-5 font-medium text-gray-300 -md:text-center text-start">
					{title}
				</SVPageTitle>
				<div className="text-3xl text-gray-500 type-subnav text-pretty">
					{description}
					<div className="mt-3">{descriptionExtraLine}</div>
				</div>
				<div className="flex justify-start w-full mt-8 -md:flex-center">{CTAButton}</div>
			</div>
		</div>
	</div>
);

export default SVInfoSection;
