import { type ItemFragmentFragment } from '@apps/www/src/__generated__/graphql';
import useAuthTeam from '@apps/www/src/www/hooks/useAuthTeam';
import { type UploadingItem } from '@apps/www/src/www/reducers/gridUpload';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import useGridSpacings from '@pkgs/shared-client/hooks/useGridSpacings';
import { unit } from '@pkgs/shared-client/styles/mixins';
import {
	GridHoverOverlayUISize,
	type GridColumnsConfigID,
	type GridSpacingsConfigID,
} from '@pkgs/shared/constants';
import BoardUserRole from '@pkgs/shared/enums/BoardUserRole';
import boardUserRoleHasBoardUserRolePrivileges from '@pkgs/shared/helpers/boardUserRoleHasBoardUserRolePrivileges';
import clsx from 'clsx';
import { type ValueOf } from 'next/dist/shared/lib/constants';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { createSelector } from 'reselect';
import { twMerge } from 'tailwind-merge';
import SVButton, { SVButtonUSES } from './SVButton';
import type { Props as GridItemProps } from './SVGridItem';
import SVGridLoading, { SkeletonItem } from './SVGridLoading';
import SVLink from './SVLink';
import SVLoadingIndicator from './SVLoadingIndicator';
import SVPageMargins from './SVPageMargins';
import SVVirtualList from './SVVirtualList';

const SOURCE_TYPES = {
	FEED: 'feed',
	TEAM_FEED: 'team_feed',
	POPULAR: 'popular',
	SEARCH: 'search',
	BOARD: 'board',
	RELATED: 'related',
	USER: 'user',
} as const;

type ColumnWithHeight = {
	items: LikeItem[];
	height: number;
};

const columnsSelector = createSelector(
	(items: LikeItem[]) => items,
	(_: LikeItem[], columnsCount: number) => columnsCount,
	(_: LikeItem[], __: number, spacingPerc: number) => spacingPerc,
	(items, columnsCount, spacingPerc) => {
		if (!columnsCount || !items || !items.length) {
			return [];
		}

		const columns: Array<ColumnWithHeight> = Array(columnsCount)
			.fill(true)
			.map(() => ({ items: [], height: 0 }));

		const spacingRatio = spacingPerc / (1 - spacingPerc);

		return items
			.reduce((columns, item) => {
				if (!item || !item.asset.image) {
					return columns;
				}

				// Find shortest column
				const shortestColumn =
					columns.reduce(
						(
							acc: { minHeight: number | null; column: ColumnWithHeight | null },
							column,
						) => {
							if (acc.minHeight === null || column.height < acc.minHeight) {
								acc.minHeight = column.height;
								acc.column = column;
							}

							return acc;
						},
						{ minHeight: null, column: null },
					).column || columns[0];

				shortestColumn.height += item.asset.image.ratio + spacingRatio;
				shortestColumn.items.push(item);

				return columns;
			}, columns)
			.map((column) => column.items);
	},
);

const _EmptyMessage = ({
	role,
	sourceType,
	columnsConfigID,
	spacingConfigID,
}: Pick<Props, 'role' | 'sourceType' | 'columnsConfigID' | 'spacingConfigID'>) => {
	let emptyMessage: React.ReactNode = null;
	const router = useRouter();
	const team = useAuthTeam();

	if (
		sourceType === SOURCE_TYPES.TEAM_FEED &&
		boardUserRoleHasBoardUserRolePrivileges(role, BoardUserRole.ADMIN) &&
		// Don't show this message when displaying a board on the feed page
		router.pathname !== '/team/[boardSlug]' &&
		!team?.feedBoardID
	) {
		return (
			<div className='w-full'>
				<SVPageMargins className='flex flex-col items-center'>
					<p className='mt-32 text-6xl'>You don't have anyone added to your team.</p>
					<SVButton
						Component={SVLink}
						to="/team/users/#invite"
						use={SVButtonUSES.PRIMARY}
						className='my-20 text-white bg-brand'
					>
						Add team members
					</SVButton>
				</SVPageMargins>
				<div className={'w-full'}>
					<SVGridLoading
						animation={false}
						spacingConfigID={spacingConfigID}
						columnsConfigID={columnsConfigID}
					/>
				</div>
			</div>
		);
	} else if (sourceType === SOURCE_TYPES.RELATED) {
		emptyMessage = <p>We couldn't find any related saves</p>;
	} else if (sourceType === SOURCE_TYPES.FEED) {
		emptyMessage = (
			<>
				<p>The users you are following did not save anything yet.</p>
				<br />
				<br />
				<SVButton Component={SVLink} to="/a/following/" use={SVButtonUSES.PRIMARY}>
					Follow some users to improve your feed
				</SVButton>
			</>
		);
	} else if (sourceType === SOURCE_TYPES.TEAM_FEED) {
		emptyMessage = (
			<>
				<p>Your team mates did not save anything yet.</p>
			</>
		);
	} else if (sourceType === SOURCE_TYPES.SEARCH) {
		emptyMessage = <p>Bummer :( we couldn{"'"}t find anything. Try something else.</p>;
	} else if (boardUserRoleHasBoardUserRolePrivileges(role, BoardUserRole.VIEWER)) {
		emptyMessage = (
			<>
				<p>There is nothing here yet.</p>
				{sourceType !== SOURCE_TYPES.BOARD && (
					<>
						<br />
						<br />
						<SVButton
							Component={SVLink}
							to="/a/following/"
							use={SVButtonUSES.PRIMARY}
						>
							Follow some users and start saving
						</SVButton>
					</>
				)}
			</>
		);
	} else if (role == null) {
		emptyMessage = (
			<>
				<p>This user did not save anything yet.</p>
				<br />
				<br />
				<p>Come back later.</p>
			</>
		);
	} else {
		emptyMessage = <p>There is nothing here.</p>;
	}

	return (
		<SVPageMargins>
			<div className="py-12 text-center">{emptyMessage}</div>
		</SVPageMargins>
	);
};

export type LikeItem = ItemFragmentFragment | UploadingItem | SkeletonItem;

export type ItemPassthroughProps = {
	role: Props['role'];
	isStatic: Props['isStatic'];
	isSkeleton: Props['isSkeleton'];
	isSortLoading: Props['isSortLoading'];
	autoPlayGIFs: Props['autoPlayGIFs'];
	overlayUISize: GridItemProps['overlayUISize'];
	onClick: Props['onItemClick'];
	isRelatedItems: GridItemProps['isRelatedItems'];
	sourceType: Props['sourceType'];
};

type ColumnProps = Omit<ItemPassthroughProps, 'onClick' | 'overlayUISize'> &
	Pick<Props, 'keyExtractor' | 'onPaginate' | 'onItemClick' | 'className'> & {
		items: NonNullable<Props['items']>;
		renderItem: (item: LikeItem, props: ItemPassthroughProps) => JSX.Element;
		spacingRatio: number;
		spacingPerc: number;
		style?: React.CSSProperties;
	};

// useResizeAware() reports the width as a rounded number, so we need to
// use getBoundingClientRect() to get the precise width
function useElementPreciseWidth(elementRef: React.RefObject<HTMLDivElement>) {
	const [width, setWidth] = useState<number | null>(null);

	const update = useEventCallback(() => {
		if (elementRef.current) {
			const boundingClientRect = elementRef.current.getBoundingClientRect();

			setWidth(boundingClientRect.width);
		}
	});

	useEffect(() => {
		update();

		window.addEventListener('resize', update);
		window.addEventListener('DOMContentLoaded', update);

		// When container changes size
		const resizeObserver = new ResizeObserver(update);
		if (elementRef.current) {
			resizeObserver.observe(elementRef.current);
		}

		return () => {
			window.removeEventListener('resize', update);
			window.removeEventListener('DOMContentLoaded', update);
			resizeObserver.disconnect();
		};
	}, [elementRef, update]);

	return width;
}

const _Column = ({
	role,
	isSortLoading,
	isStatic,
	isSkeleton,
	autoPlayGIFs,
	items,
	keyExtractor,
	onPaginate,
	renderItem,
	spacingRatio,
	onItemClick,
	spacingPerc,
	className,
	style,
	isRelatedItems,
	sourceType,
}: ColumnProps) => {
	const elementRef = useRef<HTMLDivElement>(null);
	const columnWidth = useElementPreciseWidth(elementRef);

	let overlayUISize: GridHoverOverlayUISize = 'large';

	if (columnWidth) {
		const itemWidth = columnWidth * (1 - spacingRatio);

		if (itemWidth < 230) {
			overlayUISize = 'small';
		} else if (itemWidth < 375) {
			overlayUISize = 'medium';
		}
	}

	const itemProps = {
		role,
		isStatic,
		isSkeleton,
		isSortLoading,
		// isEditing,
		autoPlayGIFs,
		overlayUISize,
		onClick: onItemClick,
		isRelatedItems,
		sourceType,
	};

	let virtualList: React.ReactNode = null;

	if (isStatic || columnWidth) {
		const renderedItems = items.map((item) => (
			<div
				key={keyExtractor(item)}
				style={{
					padding: `0 ${unit(spacingPerc * 0.5, '%')} ${unit(spacingPerc, '%')} ${unit(
						spacingPerc * 0.5,
						'%',
					)}`,
				}}
			>
				{renderItem(item, itemProps)}
			</div>
		));

		if (isStatic) {
			return (
				<div className={twMerge('flex-1', className)} style={style}>
					{renderedItems}
				</div>
			);
		} else if (columnWidth) {
			const inverseSpacingRatio = 1 - spacingRatio;
			const width = columnWidth * inverseSpacingRatio;

			const bottomPadding = columnWidth * spacingRatio;
			const heights = items.map((item) => item.asset.image.ratio * width + bottomPadding);

			virtualList = (
				<SVVirtualList
					heights={heights}
					onPaginate={onPaginate}
					className={className}
					style={style}
				>
					{renderedItems}
				</SVVirtualList>
			);
		}
	}

	return (
		<div className="relative flex-1" ref={elementRef}>
			{virtualList}
		</div>
	);
};

export type Props = {
	columnsConfigID: GridColumnsConfigID;
	spacingConfigID: GridSpacingsConfigID;
	items: LikeItem[] | undefined;
	renderItem: (item: LikeItem, props: ItemPassthroughProps) => JSX.Element;
	keyExtractor: (item: LikeItem) => string;

	role?: GridItemProps['role'] | null;
	sourceType?: ValueOf<typeof SOURCE_TYPES>;

	isLoading?: boolean;
	loadingSkeletonCount?: number;
	isLoaded?: boolean;
	onPaginate?: (() => void) | null;

	isStatic: GridItemProps['isStatic'];
	isSorting?: boolean;
	isSortLoading?: GridItemProps['isSortLoading'];
	autoPlayGIFs?: GridItemProps['autoPlayGIFs'];
	isSkeleton?: GridItemProps['isSkeleton'];

	onItemClick?: GridItemProps['onClick'];

	className?: string;
	getColumnClassName?: (columnIndex: number) => string;
	getColumnStyle?: (columnIndex: number) => React.CSSProperties;
};

const SVGrid = ({
	spacingConfigID = 1,
	columnsConfigID = 1,
	items,
	renderItem,
	keyExtractor,

	role,
	sourceType,

	isLoading,
	loadingSkeletonCount,
	isLoaded,
	onPaginate,

	isStatic,
	isSorting,
	isSortLoading,
	autoPlayGIFs,
	isSkeleton,

	onItemClick,

	className,
	getColumnClassName,
	getColumnStyle,
}: Props) => {
	const { spacingPerc } = useGridSpacings(columnsConfigID, spacingConfigID);

	const spacingRatio = spacingPerc / 100;
	const isRelatedItems = sourceType === SOURCE_TYPES.RELATED;

	const columns =
		columnsConfigID && items ? columnsSelector(items, columnsConfigID, spacingRatio) : [];
	const columnsCount = columns.length;

	const emptyMessage =
		isLoaded && items && !items.length ? (
			<_EmptyMessage
				key={'error'}
				role={role}
				sourceType={sourceType}
				spacingConfigID={spacingConfigID}
				columnsConfigID={columnsConfigID}
			/>
		) : null;

	let processedItems: LikeItem[] | undefined = items;
	let processedIsLoading = isLoading;

	// viewportName is null on server and client first render. Render a
	// loading spin while we wait for the viewport to be set.
	if (!columnsConfigID) {
		processedItems = undefined;
		processedIsLoading = true;
	}

	if (processedIsLoading && !processedItems?.length && !isSkeleton) {
		return (
			<SVGridLoading
				spacingConfigID={spacingConfigID}
				columnsConfigID={columnsConfigID}
				count={loadingSkeletonCount}
			/>
		);
	}

	return (
		<>
			<div
				// min-h-dvh is to make sure the grid doesn't jump when transitioning from skeleton to fully loaded
				// problem is more prominent on related saves grid
				className={twMerge(
					clsx('flex min-h-dvh', isSorting && 'pointer-events-none'),
					className,
				)}
				style={{
					padding: `0 ${unit((spacingPerc / columnsCount) * 0.5, '%')} 0 ${unit(
						(spacingPerc / columnsCount) * 0.5,
						'%',
					)}`,
				}}
			>
				{emptyMessage}
				<React.Fragment key={'columns'}>
					{processedItems &&
						!!processedItems.length &&
						columns.map((columnItems, index) => (
							<_Column
								key={index}
								className={
									getColumnClassName ? getColumnClassName(index) : undefined
								}
								style={getColumnStyle ? getColumnStyle(index) : undefined}
								spacingPerc={spacingPerc}
								spacingRatio={spacingRatio}
								items={columnItems}
								renderItem={renderItem}
								keyExtractor={keyExtractor}
								onPaginate={onPaginate}
								isStatic={isStatic}
								isSkeleton={isSkeleton}
								isSortLoading={isSortLoading}
								role={role}
								autoPlayGIFs={autoPlayGIFs}
								onItemClick={onItemClick}
								isRelatedItems={isRelatedItems}
								sourceType={sourceType}
							/>
						))}
				</React.Fragment>
			</div>
			{processedIsLoading && !isSkeleton && <SVLoadingIndicator />}
		</>
	);
};

SVGrid.SOURCE_TYPES = SOURCE_TYPES;

export default SVGrid;
