import {
	type Asset,
	type AssetColor,
	type AssetImage,
	type Item,
} from '@apps/www/src/__generated__/graphql';
import {
	UPLOAD_ERROR,
	UPLOAD_QUEUED,
	UPLOAD_UPLOADING,
	type UploadingItem,
} from '@apps/www/src/www/reducers/gridUpload';
import { isTouch } from '@pkgs/shared-client/helpers/dom';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import IconErrorSVG from '@pkgs/shared-client/img/icon-error-inlined.svg';
import IconVideoSVG from '@pkgs/shared-client/img/icon-video-inlined.svg';
import { unit } from '@pkgs/shared-client/styles/mixins';
import { type GridHoverOverlayUISize } from '@pkgs/shared/constants';
import AssetType from '@pkgs/shared/enums/AssetType';
import BoardUserRole from '@pkgs/shared/enums/BoardUserRole';
import boardUserRoleHasBoardUserRolePrivileges from '@pkgs/shared/helpers/boardUserRoleHasBoardUserRolePrivileges';
import clsx from 'clsx';
import memoize from 'lodash/memoize';
import { type ParsedUrlQuery } from 'node:querystring';
import React, { useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import SVDynamicImage from './SVDynamicImage';
import type { LikeItem } from './SVGrid';
import SVGrid from './SVGrid';
import SVLazyImage from './SVLazyImage';
import SVLink from './SVLink';

// TODO: Improve image rendering/decoding with web-worker

export const GRID_ITEM_CLASS_NAME = 'grid-item';

export const getShortIDFromElementID = memoize((elementID) =>
	String(elementID).split('grid-item-').join(''),
);

export const createElementIDWithShortID = memoize((shortID) => `grid-item-${shortID}`);

const _NoImageWrapper = ({
	id,
	ratio,
	color,
	className,
	style,
	children,
}: React.PropsWithChildren<{
	id?: string;
	ratio: number;
	color: AssetColor['color'];
	className?: string;
	style?: React.HTMLProps<HTMLDivElement>['style'];
}>) => (
	<div
		id={id}
		className={twMerge('relative', className)}
		style={{
			paddingBottom: unit(ratio * 100, '%'),
			backgroundColor: color,
			...style,
		}}
	>
		{children}
	</div>
);

const _Skeleton = ({
	ratio,
	color,
	isStatic,
}: {
	ratio: number;
	color: AssetColor['color'];
	isStatic: boolean | undefined;
}) => (
	<_NoImageWrapper
		className={!isStatic ? 'animate-pulsing' : ''}
		style={
			!isStatic
				? {
						animationDuration: `${ratio * 1}s`,
				  }
				: undefined
		}
		ratio={ratio}
		color={color}
	/>
);

const _UploadingText = ({
	className,
	children,
}: React.PropsWithChildren<{ className?: string }>) => (
	<div className={twMerge('type-small relative text-center font-semibold', className)}>
		{children}
	</div>
);

const _Item = React.memo(
	({
		shortID,
		color,
		ratio,
		thumbnail,
		original,
		type,
		url,
		isUploading,
		uploadStatus,
		uploadProgress,
		uploadErrorMessage,
		isSelected,

		routerPathname,
		routerQuery,

		canMove,
		renderHoverOverlay,

		isSorting,

		isOver,
		onMouseEnter,
		onMouseLeave,
		onClick,

		// From SVGrid
		// isOwner,
		isStatic,
		autoPlayGIFs,
		overlayUISize,
		isSkeleton,
		animation,

		forceThumbnail,
	}: Omit<
		Props,
		'isOwner' | 'role' | 'canSort' | 'onClick' | 'sourceType' | 'isSaved' | 'itemID'
	> & {
		onMouseEnter: React.HTMLProps<HTMLDivElement>['onMouseEnter'];
		onMouseLeave: React.HTMLProps<HTMLDivElement>['onMouseLeave'];
		onClick: (event: React.UIEvent) => void;
		canMove: boolean;
		isOver: boolean;
	}) => {
		if (isSorting) {
			return (
				<_NoImageWrapper
					id={createElementIDWithShortID(shortID)}
					className={GRID_ITEM_CLASS_NAME}
					ratio={ratio}
					color={color}
				>
					<div
						className="absolute inset-0"
						style={{ border: `2px dashed rgba(255, 255, 255, 0.5)` }}
					/>
				</_NoImageWrapper>
			);
		}

		if (isUploading) {
			return (
				<_NoImageWrapper
					id={createElementIDWithShortID(shortID)}
					className={GRID_ITEM_CLASS_NAME}
					ratio={ratio}
					color={color}
				>
					{thumbnail && (
						<SVDynamicImage
							className="absolute inset-0"
							src={thumbnail}
							ratio={ratio}
							color={color}
							cover={true}
						/>
					)}

					<div
						className={clsx(
							'flex-center absolute inset-0 h-full w-full flex-col overflow-hidden bg-center bg-no-repeat p-[12%] text-white',
							uploadStatus === UPLOAD_ERROR ? 'bg-danger' : 'bg-image-loading',
							thumbnail && 'bg-opacity-80',
						)}
					>
						{(uploadStatus === UPLOAD_UPLOADING || uploadStatus === UPLOAD_ERROR) && (
							<div
								className={clsx(
									'bg-brand absolute inset-0 h-full w-full',
									thumbnail && 'opacity-90',
								)}
								style={{
									transform: `translateX(${unit(
										Math.floor((1 - (uploadProgress || 0)) * 100 * -1) - 1,
										'%',
									)})`,
								}}
							/>
						)}
						{uploadStatus === UPLOAD_QUEUED && <_UploadingText>Waiting</_UploadingText>}
						{uploadStatus === UPLOAD_UPLOADING && (
							<_UploadingText className="[text-shadow:1px_1px_0_rgba(0,0,0,0.03),-1px_1px_0_rgba(0,0,0,0.03),1px_-1px_0_rgba(0,0,0,0.03),-1px_-1px_0_rgba(0,0,0,0.03)]">
								Uploading
							</_UploadingText>
						)}
						{uploadStatus === UPLOAD_ERROR && (
							<>
								<IconErrorSVG className="mb-3" />
								<_UploadingText>{uploadErrorMessage || 'Error'}</_UploadingText>
							</>
						)}
					</div>
				</_NoImageWrapper>
			);
		}

		if (isSkeleton) {
			return <_Skeleton isStatic={!animation} ratio={ratio} color={color} />;
		}

		const isVideo = type === AssetType.VIDEO;
		const imageSrc = isStatic && !forceThumbnail ? original : thumbnail;

		const placeholder = <_NoImageWrapper ratio={ratio} color={color} />;

		if (isStatic) {
			return (
				<SVDynamicImage
					src={imageSrc}
					placeholder={placeholder}
					ratio={ratio}
					color={color}
				/>
			);
		}

		const linkTo = {
			pathname: routerPathname,
			query: { ...routerQuery, itemShortID: shortID },
		};
		const linkAs = url;

		return (
			<div
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				className={clsx('group relative', GRID_ITEM_CLASS_NAME)}
				id={createElementIDWithShortID(shortID)}
			>
				<SVLink
					to={linkTo}
					as={linkAs}
					onClick={onClick}
					scroll={false}
					prefetch={false}
					shallow={true}
				>
					{isVideo && (isOver || autoPlayGIFs) ? (
						<div className="overflow-hidden">
							<video
								className="h-auto w-full"
								src={original}
								poster={thumbnail}
								playsInline
								autoPlay
								loop
								muted
							/>
						</div>
					) : (
						<SVDynamicImage
							Component={SVLazyImage}
							src={imageSrc}
							placeholder={placeholder}
							ratio={ratio}
							color={color}
							className="overflow-hidden"
						/>
					)}
					{!isOver && !autoPlayGIFs && isVideo && (
						<IconVideoSVG className="absolute bottom-3 left-3 text-white" />
					)}
					{isOver && renderHoverOverlay
						? renderHoverOverlay({ uiSize: overlayUISize, canMove })
						: null}
					<div
						className={clsx(
							'grid-show-on-editing border-brand group-hover:bg-brand/20 absolute inset-0 h-full w-full transition-all',
							isSelected ? 'bg-brand/40 border-[12px]' : 'bg-brand/0 border-0',
						)}
					/>
				</SVLink>
			</div>
		);
	},
);

export type Props = {
	isSelected?: boolean;
	isUploading: boolean;
	isSorting?: boolean;
	isStatic?: boolean;
	isSortLoading?: boolean;
	isOwner: boolean;
	isSkeleton: boolean | undefined;
	role: ValueOf<typeof BoardUserRole> | null | undefined;
	itemID: Item['_id'];
	shortID: Item['shortID'];
	color: AssetColor['color'];
	ratio: AssetImage['ratio'];
	thumbnail: AssetImage['thumbnail'];
	original: AssetImage['original'];
	type: Asset['type'];
	url: Item['url'];
	uploadStatus: UploadingItem['upload']['status'] | null;
	uploadProgress: UploadingItem['upload']['progress'] | null;
	uploadErrorMessage: string | null | undefined;
	autoPlayGIFs?: boolean;
	overlayUISize: GridHoverOverlayUISize;
	onClick?: (itemID: Item['_id'], event: React.UIEvent) => void;
	routerPathname?: string;
	routerQuery?: ParsedUrlQuery;
	isSaved: boolean;
	sourceType?: ValueOf<typeof SVGrid.SOURCE_TYPES>;
	canSort?: boolean;
	forceThumbnail?: boolean;
	animation?: boolean;
	isRelatedItems?: boolean;
	renderHoverOverlay?: (props: {
		uiSize: GridHoverOverlayUISize;
		canMove: boolean;
	}) => React.ReactNode;
};

function isUploadingItem(item: LikeItem): item is UploadingItem {
	return item.hasOwnProperty('upload');
}

const SVGridItem = ({
	sourceType,
	canSort,
	isOwner,
	role,
	isStatic,
	itemID,
	onClick,
	...props
}: Props) => {
	const [isOver, setIsOver] = useState(false);

	const canMove = useMemo(() => {
		// Don't allow to move on feed, popular or search grids, doesn't matter if its owner or not
		if (
			sourceType &&
			sourceType !== SVGrid.SOURCE_TYPES.USER &&
			sourceType !== SVGrid.SOURCE_TYPES.BOARD
		) {
			return false;
		}

		// Don't allow to move if sort method is not custom
		if (!canSort) {
			return false;
		}

		return isOwner || boardUserRoleHasBoardUserRolePrivileges(role, BoardUserRole.EDITOR);
	}, [sourceType, canSort, isOwner, role]);

	const handleMouseEnter = useEventCallback(() => {
		if (isStatic || isTouch()) {
			return;
		}

		setIsOver(true);
	});

	const handleMouseLeave = useEventCallback(() => {
		if (isStatic || isTouch()) {
			return;
		}

		setIsOver(false);
	});

	const handleClick = useEventCallback((event: React.UIEvent) => {
		if (onClick) {
			return onClick(itemID, event);
		}
	});

	return (
		<_Item
			{...props}
			isOver={isOver}
			canMove={canMove}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onClick={handleClick}
		/>
	);
};

SVGridItem.itemToProps = (item: LikeItem) => {
	const isUpload = isUploadingItem(item);

	return {
		key: item._id,
		itemID: item._id,
		shortID: item.shortID,

		color: item.asset.colors[0].color,
		thumbnail: item.asset.image.thumbnail,
		original: item.asset.image.original,
		ratio: item.asset.image.ratio,
		type: item.asset.type,

		url: item.url,
		isUploading: isUpload ? true : false,
		uploadStatus: isUpload ? item.upload.status : null,
		uploadProgress: isUpload ? item.upload.progress : null,
		uploadErrorMessage: isUpload ? item.upload.error?.message : null,
		isOwner: item.isOwner,
		isSaved: item.asset.isSaved,
	};
};

SVGridItem._Item = _Item; // for tests only

export default SVGridItem;
