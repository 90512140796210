import SVImage from '@pkgs/shared-client/components/SVImage';
import SVPageTitle from '@pkgs/shared-client/components/SVPageTitle';
import logoBrandsSVG from '@pkgs/shared-client/img/upgrade/brands/logo-brands.svg';
import buildForYouImage4 from '@pkgs/shared-client/img/upgrade/Image-1.png';
import buildForYouImage2 from '@pkgs/shared-client/img/upgrade/Image-2.png';
import buildForYouImage1 from '@pkgs/shared-client/img/upgrade/Image-3.png';
import buildForYouImage6 from '@pkgs/shared-client/img/upgrade/Image-4.png';
import buildForYouImage3 from '@pkgs/shared-client/img/upgrade/Image-5.png';

const _Brands = () => {
	return (
		<div className="relative w-full mt-16 overflow-hidden">
			<div className="flex animate-scroll">
				<div className="flex shrink-0">
					<SVImage src={logoBrandsSVG} alt="Marcas SVG" />
				</div>
				<div className="flex shrink-0">
					<SVImage src={logoBrandsSVG} alt="Marcas SVG" />
				</div>
			</div>
		</div>
	);
};

const _SVBrands = ({ withSideMenu }: { withSideMenu?: boolean }) => {
	return (
		<div className="relative pb-32 mt-40 text-center">
			<SVPageTitle>
				<span className="text-gray-200 type-title">
					Brands <br className="sm:hidden" />
					love it
				</span>
			</SVPageTitle>
			<p className="max-w-3xl m-auto mb-8 font-light text-gray-400 type-subtitle">
				Our users are <br className="sm:hidden" />
				tastemakers working at <br className="sm:hidden" />
				the top companies <br className="md:hidden" />
				of the creative&nbsp;industry.
			</p>
			<div className="w-full flex-center">
				<div className="-lg:max-w-[800px] max-w-[1112px]">
					<_Brands />
				</div>
			</div>
			{!withSideMenu ? (
				<div className="hidden-lg">
					<SVImage
						src={buildForYouImage1}
						data-speed={0.03}
						data-start="top center"
						loading="lazy"
						alt="still have so much to win - mbappe"
						className="-sm:top-[-37px] -md:top-[-10px] -sm:w-[17vw] absolute left-[-1.5vw] top-0 w-[10vw]"
					/>
					<SVImage
						src={buildForYouImage2}
						data-speed={0.02}
						data-start="top center"
						loading="lazy"
						alt="car at sunset"
						className="-sm:w-[12vw] -md:top-[81px] -sm:top-[51px] -md:left-[6vw] absolute left-[5vw] top-[170px] w-[8vw]"
					/>
					<SVImage
						src={buildForYouImage3}
						data-speed={0.04}
						data-start="top center"
						loading="lazy"
						alt="this is clay"
						className="-sm:top-[125px] -md:top-[180px] -sm:w-[16vw] absolute left-0 top-[341px] w-[10vw]"
					/>
					<SVImage
						src={buildForYouImage4}
						data-speed={0.02}
						data-start="top center"
						loading="lazy"
						alt="bicycle"
						className="-md:top-[-17px] -sm:w-[11vw] absolute right-[-2.5vw] top-0 w-[11vw]"
					/>
					<SVImage
						src={buildForYouImage6}
						data-speed={0.01}
						data-start="top center"
						loading="lazy"
						alt="car"
						className="-sm:top-[53px] -md:top-[125px] -sm:w-[23vw] absolute right-[-3vw] top-[240px] w-[13vw]"
					/>
				</div>
			) : null}
		</div>
	);
};

const SVBrands = () => {
	return <_SVBrands />;
};

SVBrands.Brands = _Brands;

export default SVBrands;
