import useViewportName from '@apps/www/src/www/hooks/useViewportName';
import SVButton, { SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import SVImage from '@pkgs/shared-client/components/SVImage';
import SVPageMargins from '@pkgs/shared-client/components/SVPageMargins';
import ViewportKind from '@pkgs/shared/enums/ViewportKind';
import { useState } from 'react';

export interface ISocialProof {
	title: string;
	userDescription: string;
	userName: string;
	avatarURL: string;
} 

const SVSocialProfileGrid = ({
	SOCIAL_PROOF_LIST,
	showMoreCopy,
}: {
	SOCIAL_PROOF_LIST: ISocialProof[];
	showMoreCopy: string;
}) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const viewportName = useViewportName();

	const isSmallNav =
		viewportName === 'small' ||
		(!viewportName && ViewportKind.MOBILE) ||
		viewportName === 'medium' ||
		viewportName === 'medium-portrait';

	const visibleItems = isSmallNav && !isOpen ? SOCIAL_PROOF_LIST.slice(0, 5) : SOCIAL_PROOF_LIST;

	return (
		<SVPageMargins>
			<div className="relative w-full max-w-screen-xl mx-auto flex-center">
				<div className="relative gap-8 space-y-8 -sm:columns-1 -md:columns-2 -lg:columns-3 columns-4">
					{visibleItems.map((item, index) => (
						<div
							key={index}
							className="p-4 text-center border border-gray-800 break-inside-avoid rounded-xl"
						>
							<div className="text-xl text-gray-500">{item.title}</div>
							<div className="mt-4 flex-center">
								<SVImage
									src={item.avatarURL}
									className="w-5 h-5 rounded-full"
									alt={item.userName + ' avatar'}
								/>
							</div>
							<div className="mt-3 text-lg text-gray-300">{item.userName}</div>
							<div className="text-sm text-gray-500">{item.userDescription}</div>
						</div>
					))}
					{isSmallNav && !isOpen ? (
						<div className="absolute bottom-0 left-0 right-0 mt-5 flex h-[300px] items-end justify-center bg-gradient-to-t from-black to-transparent">
							<SVButton
								use={SVButtonUSES.PRIMARY}
								onClick={() => setIsOpen(true)}
								className="px-8 py-6 mb-5 font-medium w-fit"
							>
								{showMoreCopy}
							</SVButton>
						</div>
					) : null}
				</div>
			</div>
		</SVPageMargins>
	);
};

export default SVSocialProfileGrid;
