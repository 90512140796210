/**
 * List of sources for the upgrade button, for tracking.
 */

const UpgradeButtonSource = {
	NAV_BAR: 'nav_bar', // done
	NAV_MENU: 'nav_menu', // done
	REACHED_LIMIT_MODAL: 'reached_limit_modal', // done
	UPGRADE_REQUIRED_ITEM_LIMIT_MODAL: 'upgrade_required_modal_item_limit', // done
	UPGRADE_REQUIRED_BOARD_LIMIT_MODAL: 'upgrade_required_modal_board_limit', // done
	UPGRADE_REQUIRED_PRINT_MODAL: 'upgrade_required_modal_print', // done
	UPGRADE_REQUIRED_PRIVATE_MODAL: 'upgrade_required_modal_private', // done
	UPGRADE_REQUIRED_SHARED_MODAL: 'upgrade_required_modal_shared', // done
	UPGRADE_REQUIRED_MANAGE_TEAM_MODAL: 'upgrade_required_modal_manage_team', // done
	UPGRADE_REQUIRED_CREATE_SITE_MODAL: 'upgrade_required_modal_create_site', // done
	UPGRADE_REQUIRED_RIGHT_CLICK_MODAL: 'upgrade_required_modal_right_click', // done
	UPGRADE_REQUIRED_HIDE_ASSETS_MODAL: 'upgrade_required_modal_hide_assets', // done
	LOW_QUALITY_UPSELL: 'low_quality_upsell', // done
	END_OF_GRID_UPSELL: 'end_of_grid_upsell', // done
	DROPBOX_UPSELL: 'dropbox_upsell', // done
	AUTOPLAY_GIF_UPSELL: 'autoplay_gif_upsell', // done
	BLOCKED_FEED: 'blocked_feed', // done
} as const;

export default UpgradeButtonSource;
