import useAuthSubscriptionIsExpired from '@apps/www/src/www/hooks/useAuthSubscriptionIsExpired';
import { useRouter } from 'next/router';

const useCanSeeUpgradeWarning = () => {
	const router = useRouter();

	const routerPath = router.asPath;

	const hasExpiredSubscription = Boolean(useAuthSubscriptionIsExpired());

	// Don't show upgrade warning on home page if user has expired subscription
	// because we're already blocking the feed and displaying a notice in the
	// middle of the page
	if (routerPath === '/' && hasExpiredSubscription) {
		return false;
	}

	if (
		routerPath.indexOf('/billing/') !== 0 &&
		routerPath.indexOf('/upgrade/') !== 0 &&
		routerPath.indexOf('/profile/') !== 0 &&
		routerPath.indexOf('/team/settings/') !== 0 &&
		routerPath.indexOf('/team/users/') !== 0 &&
		routerPath.indexOf('/contact-sales/') !== 0 &&
		routerPath.indexOf('/terms/') !== 0 &&
		routerPath.indexOf('/privacy/') !== 0 &&
		routerPath.indexOf('/aup/') !== 0 &&
		routerPath.indexOf('/copyright-and-ip-policy/') !== 0 &&
		routerPath.indexOf('/site-maker/edit/') !== 0
	)
		return true;

	return false;
};

export default useCanSeeUpgradeWarning;
