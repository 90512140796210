import SVFooterImageGallery from '@apps/www/src/www/components/SVFooterImageGallery';
import SVInfoSection from '@apps/www/src/www/components/SVInfoSection';
import SVSocialProfileGrid, {
	ISocialProof,
} from '@apps/www/src/www/components/SVSocialProfileGrid';
import SVPageMeta from '@apps/www/src/www/containers/SVPageMeta';
import SVStripePaymentButtonContainer from '@apps/www/src/www/containers/SVStripePaymentButtonContainer';
import useAuthActiveSubscriptionTiers from '@apps/www/src/www/hooks/useAuthActiveSubscriptionTiers';
import useAuthSubscriptionIsExpired from '@apps/www/src/www/hooks/useAuthSubscriptionIsExpired';
import useAuthTeam from '@apps/www/src/www/hooks/useAuthTeam';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import useViewportName from '@apps/www/src/www/hooks/useViewportName';
import SVNewTeamModal from '@apps/www/src/www/modals/SVNewTeamModal';
import SVCheckoutSubscriptionOverlay from '@apps/www/src/www/overlays/SVCheckoutSubscriptionOverlay';
import SVBrands from '@pkgs/shared-client/components/SVBrands';
import SVButton, { SVButtonSIZES, SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import SVImage from '@pkgs/shared-client/components/SVImage';
import SVLandingPageFooter from '@pkgs/shared-client/components/SVLandingPageFooter';
import SVModal from '@pkgs/shared-client/components/SVModal';
import SVPageHeader from '@pkgs/shared-client/components/SVPageHeader';
import SVPageMargins from '@pkgs/shared-client/components/SVPageMargins';
import SVPageSubtitle from '@pkgs/shared-client/components/SVPageSubtitle';
import SVPageTitle from '@pkgs/shared-client/components/SVPageTitle';
import SVToggle from '@pkgs/shared-client/components/SVToggle';
import config from '@pkgs/shared-client/config';
import SVWithBodyClass from '@pkgs/shared-client/containers/SVWithBodyClass';
import { scrollToElement } from '@pkgs/shared-client/helpers/dom';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import SVAndreSouzaAvatar from '@pkgs/shared-client/img/course/social_proof/andre_souza_avatar.png';
import SVAshleyGauntSeoAvatar from '@pkgs/shared-client/img/course/social_proof/ashley_gaunt_seo_avatar.png';
import SVFabioSassoAvatar from '@pkgs/shared-client/img/course/social_proof/fabio_sasso_avatar.png';
import SVJoshKillAvatar from '@pkgs/shared-client/img/course/social_proof/josh_kill_avatar.png';
import SVManvydasKugisAvatar from '@pkgs/shared-client/img/course/social_proof/manvydas_kugis_avatar.png';
import SVMichaelLashfordAvatar from '@pkgs/shared-client/img/course/social_proof/michael_lashford_avatar.png';
import SVMilyMcClellandAvatar from '@pkgs/shared-client/img/course/social_proof/mily_mcclelland_avatar.png';
import SVGIconCheck from '@pkgs/shared-client/img/icon-check-inlined.svg';
import avatarCard from '@pkgs/shared-client/img/upgrade/avatar-card.png';
import commentCard from '@pkgs/shared-client/img/upgrade/comment-card.png';
import heroImage1 from '@pkgs/shared-client/img/upgrade/hero-Image-1.png';
import heroImage2 from '@pkgs/shared-client/img/upgrade/hero-Image-2.png';
import heroImage3 from '@pkgs/shared-client/img/upgrade/hero-Image-3.png';
import heroImage from '@pkgs/shared-client/img/upgrade/hero-Image.png';
import cardImage from '@pkgs/shared-client/img/upgrade/person-card.png';
import SVRaySisonAvatar from '@pkgs/shared-client/img/upgrade/raysison.png';
import teamFeedCard from '@pkgs/shared-client/img/upgrade/team-feed-card.png';
import templatesCard from '@pkgs/shared-client/img/upgrade/templates-card.png';
import SVUpgradePageMP4 from '@pkgs/shared-client/videos/upgrade-video.mp4';
import BillingCycle from '@pkgs/shared/enums/BillingCycle';
import PaymentButtonType from '@pkgs/shared/enums/PaymentButtonType';
import SubscriptionTier from '@pkgs/shared/enums/SubscriptionTier';
import SubscriptionType from '@pkgs/shared/enums/SubscriptionType';
import ViewportKind from '@pkgs/shared/enums/ViewportKind';
import appendQueryParamsToURL from '@pkgs/shared/helpers/appendQueryParamsToURL';
import {
	Plan,
	getPlanDiscount,
	getPlanFeatures,
	getPlanFromSubscriptionTierAndBillingCycle,
	getPlanMonthlyPrice,
	getSubscriptionTierLabel,
	getSubscriptionTypeFromTier,
} from '@pkgs/shared/plans';
import clsx from 'clsx';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

gsap.registerPlugin(ScrollTrigger);

export const getUpgradePageTierConfig = (tier: ValueOf<typeof SubscriptionTier>) => {
	const defaultTierConfig = {
		tier,
		name: getSubscriptionTierLabel(tier),
		actionLabel: 'Choose Premium',
		getPriceLabel: (billingCycle: ValueOf<typeof BillingCycle>) =>
			`${
				billingCycle === BillingCycle.YEARLY
					? 'Per month, billed annually'
					: ' billed monthly'
			}`,
		getMonthlyPrice: (billingCycle: ValueOf<typeof BillingCycle>) =>
			'$' +
			getPlanMonthlyPrice(
				getPlanFromSubscriptionTierAndBillingCycle(config, tier, billingCycle),
			),
		getDiscount: (billingCycle: ValueOf<typeof BillingCycle>) => {
			const discount = getPlanDiscount(
				getPlanFromSubscriptionTierAndBillingCycle(config, tier, billingCycle),
			)?.toFixed(2);

			return discount ? `Savings $${discount}` : '';
		},
		getPlan: (billingCycle: ValueOf<typeof BillingCycle>) =>
			getPlanFromSubscriptionTierAndBillingCycle(config, tier, billingCycle),
		getFeatures: (billingCycle: ValueOf<typeof BillingCycle>) =>
			getPlanFeatures(getPlanFromSubscriptionTierAndBillingCycle(config, tier, billingCycle)),
		isCurrent: (plan: Plan | null, isPro: boolean) =>
			Boolean(plan && isPro && plan.tier === tier),
		popular: false,
	};
	switch (tier) {
		case SubscriptionTier.TEAM:
			return {
				...defaultTierConfig,
				name: 'For Teams',
				subtitle: `Better collaboration up to ${config.payment.maxPaidTeamUsers} users`,
				actionLabel: 'Start your team',
			};
		case SubscriptionTier.SITE_MAKER:
			return {
				...defaultTierConfig,
				name: 'Premium & Site',
				subtitle: 'All the Premium features plus create your custom website',
				actionLabel: 'Create your Site',
				popular: true,
			};
		case SubscriptionTier.PRO:
		default:
			return {
				...defaultTierConfig,
				subtitle: 'Unlock all our best features',
			};
	}
};

const tierConfigs = Object.values(SubscriptionTier)
	.filter((tier) => tier !== SubscriptionTier.ENTERPRISE)
	.map((tier) => getUpgradePageTierConfig(tier));

const _PlanButton = ({
	label,
	plan,
	isCurrent,
}: {
	label: string;
	plan: Plan;
	isCurrent: boolean;
}) => {
	const router = useRouter();
	const isLoggedIn = useIsLoggedIn();
	const team = useAuthTeam();

	const handleClick = useEventCallback(() => {
		if (!plan) {
			return;
		}

		// If not logged in, redirect to login page
		if (!isLoggedIn) {
			const next = router.asPath !== '/' ? router.asPath : undefined;
			router.push(appendQueryParamsToURL('/login/', next ? { next } : {}));

			return;
		}

		// If team doesn't exist, open create team modal
		if (getSubscriptionTypeFromTier(plan.tier) === SubscriptionType.TEAM && !team) {
			SVModal.open(SVNewTeamModal);

			return;
		}

		SVModal.open(SVCheckoutSubscriptionOverlay, { plan });
	});

	if (isLoggedIn && isCurrent && plan) {
		return (
			<SVButton className="w-full max-w-[186px]" isDisabled={true}>
				Your current plan
			</SVButton>
		);
	}
	return !plan?.paypalPlanID ? (
		<SVStripePaymentButtonContainer
			id="pay-with-card"
			className=" w-full max-w-[186px]"
			buttonType={PaymentButtonType.SUBSCRIBE}
			plan={plan}
			submitLabel={label}
		/>
	) : (
		<SVButton onClick={handleClick} className="w-full max-w-[186px]" use={SVButtonUSES.PRIMARY}>
			{label}
		</SVButton>
	);
};

const _Plans = ({ billingCycle }: { billingCycle: ValueOf<typeof BillingCycle> }) => {
	const activeTiers = useAuthActiveSubscriptionTiers();

	return (
		<>
			<div
				id="upgrade-plans"
				className="grid grid-cols-3 m-auto mt-8 -md:w-full -sm:gap-x-0 -sm:gap-y-6 -md:gap-x-4 -md:grid-cols-2 -sm:grid-cols-1 max-w-7xl gap-x-7 gap-y-10 "
			>
				{tierConfigs.map((tierConfig) => {
					const isCurrent = activeTiers.includes(tierConfig.tier);
					const yearlyPriceLabel = tierConfig.getPriceLabel(billingCycle);

					return (
						<div key={tierConfig.name} className="flex flex-col flex-1 -sm:w-full">
							<div className="relative flex flex-col items-center justify-center flex-grow px-8 text-secondary -sm:py-12 -sm:px-8 rounded-xl py-7 ring-1 ring-gray-900 md:py-12">
								<div
									className={clsx(
										'flex w-full flex-col items-center justify-center',
										!tierConfig.popular && '-sm:-mt-5',
									)}
								>
									<div className="w-full h-6 mb-6 -sm:h-auto">
										{tierConfig.popular ? (
											<div className="flex justify-center">
												<div className="px-3 py-1 text-white rounded-full bg-brand">
													Most Popular
												</div>
											</div>
										) : null}
									</div>
									<div className="flex flex-col items-center justify-center space-y-2">
										<p className="font-semibold type-subtitle ">
											{tierConfig.name}
										</p>
										<div className="h-8 text-center text-gray-500 type-small">
											{tierConfig.subtitle}
										</div>
									</div>
								</div>
								<div className="flex flex-col items-center justify-center w-full mb-8 space-y-2 mt-7">
									{billingCycle !== BillingCycle.MONTHLY ? (
										<p className="text-center text-gray-500 line-through type-small">
											{tierConfig.getMonthlyPrice(BillingCycle.MONTHLY)}
										</p>
									) : null}
									<p className="text-5xl font-semibold text-center">
										{tierConfig.getMonthlyPrice(billingCycle)}
									</p>
									{yearlyPriceLabel !== '' ? (
										<p className="text-gray-500 type-small">
											{yearlyPriceLabel}
										</p>
									) : null}
								</div>
								<_PlanButton
									label={tierConfig.actionLabel}
									plan={tierConfig.getPlan(billingCycle)}
									isCurrent={isCurrent}
								/>
								<ul className="flex-col flex-1 w-full mt-8 space-y-3">
									{tierConfig
										.getFeatures(BillingCycle.YEARLY)
										.map((feature, i) => (
											<li key={i} className="flex items-center space-x-2">
												<SVGIconCheck className="h-[16px] w-[16px] text-gray-600" />
												<span className="text-gray-400 type-small">
													{feature.label}
												</span>
												{feature.soon ? (
													<SVButton
														use={SVButtonUSES.VIOLATOR}
														size={SVButtonSIZES.TINY}
														className="text-primary bg-gray-800 bg-opacity-100 text-[10px] font-semibold opacity-100 ring-0"
														isDisabled={true}
													>
														SOON
													</SVButton>
												) : null}
											</li>
										))}
								</ul>
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
};

const socialProof: ISocialProof[] = [
	{
		title: 'Say goodbye to Pinterest—Savee is a game-changer for design inspiration! With stunning templates and a top-notch masterclass, it’s the perfect all-in-one resource to elevate your creativity.',
		userDescription: 'Visual Designer at Airbnb',
		userName: 'Ashley Gaunt-Seo',
		avatarURL: SVAshleyGauntSeoAvatar,
	},
	{
		title: "Savee is a designer's dream. It's a meticulously curated platform for all forms of design inspiration. From sparking new ideas to keeping me ahead of the latest trends, it’s the only resource I need to fuel my creativity.",
		userDescription: 'Senior Staff Designer at Google',
		userName: 'Mily McClelland',
		avatarURL: SVMilyMcClellandAvatar,
	},
	{
		title: 'It’s rare to find an idea executed with so much thoughtful restraint. Finding and saving visual inspiration was once polluted with so many distractions, ads and a bloated interface. Savee rescued us from all of that. It gave us back simplicity. I love it.',
		userDescription: 'Product Designer at Apple',
		userName: 'Andre Souza',
		avatarURL: SVAndreSouzaAvatar,
	},
	{
		title: 'There are a lot of ways to collect inspiration online. Savee is my choice because of its hand curated feed that makes it easy for me to stay on top of visual trends and it’s beautiful ad-free experience.',
		userDescription: 'Product & Design Leader',
		userName: 'Josh Kill',
		avatarURL: SVJoshKillAvatar,
	},
	{
		title: "Savee not only inspires me but it gives me a great view on where design is going and help me find hidden talent that I won't normally get to see through the traditional channels. I never start a project without a little inspiration from Savee.",
		userDescription: 'Partner, Design @ Playlist',
		userName: 'Ray Sison',
		avatarURL: SVRaySisonAvatar,
	},
	{
		title: "For me, the strength of Savee is in its people and their taste. It's a diverse community of curators – sharing and amplifying the beautiful and the weird, the archival and the trendy, the best of the past and what's emerging for the future. Using it means both keeping the finger on the pulse of the visual culture but also discovery of the New.",
		userDescription: 'Designer & Art Director',
		userName: 'Manvydas Kūgis',
		avatarURL: SVManvydasKugisAvatar,
	},
	{
		title: 'Savee is a visual feast curated by one of the most talented community of designers, photographers and visual artists. It is hard to not feel deeply inspired by using their service.',
		userDescription: 'Head of Design @ Uber',
		userName: 'Fabio Sasso',
		avatarURL: SVFabioSassoAvatar,
	},
	{
		title: 'Savee is my playground for visual expression. I come here to escape my projects and find sparks that quietly collide to shape my work.',
		userDescription: 'Design Principal at Cash App',
		userName: 'Michael Lashford',
		avatarURL: SVMichaelLashfordAvatar,
	},
];

const _SocialProof = () => {
	return (
		<div>
			<_Title
				title="1M+ creatives use us as their source of inspiration"
				description="A global community sharing curated inspiration that drives creativity forward."
				className="mb-16"
			/>
			<SVSocialProfileGrid SOCIAL_PROOF_LIST={socialProof} showMoreCopy="Show more" />
		</div>
	);
};

const _Title = ({
	title,
	description,
	className,
}: {
	title: JSX.Element | string;
	description: JSX.Element | string;
	className?: string;
}) => {
	return (
		<div className={clsx(' space-y-3 text-center', className)}>
			<div className="font-semibold text-gray-300 type-title -sm:type-huge -sm:text-6xl text-balance">
				{title}
			</div>
			<div className="text-gray-500 type-pagenav">{description}</div>
		</div>
	);
};

const _RequestEnterpriseDemo = () => {
	const router = useRouter();

	const handleRequestDemo = useEventCallback(() => {
		router.push('/contact-sales/');
	});

	const _Wrapper = ({
		children,
		className,
	}: {
		children: React.ReactNode;
		className?: string;
	}) => {
		return (
			<div
				className={clsx(
					'-sm:h-[180px] flex h-full w-full rounded-xl ring-1 ring-gray-900',
					className,
				)}
			>
				{children}
			</div>
		);
	};

	const _GroupCardsSmallContent = () => {
		return (
			<div className="flex-col w-full px-5 space-y-2 sm:px-32">
				<div className="flex space-x-2">
					<div className="w-1/2">
						<_Wrapper>
							<div className="w-full h-full p-4 sm:p-8">
								<SVImage
									src={avatarCard}
									alt="avatar card"
									className="object-contain w-auto h-8 rounded-xl text-start sm:h-14"
								/>
								<div className="mt-8 font-semibold text-gray-400 type-subtitle -smp:mt-4">
									Easy <br /> collaboration
								</div>
								<div className="text-gray-500 type-small">
									Keep everyone inspired <br /> with Savee.
								</div>
							</div>
						</_Wrapper>
					</div>
					<div className="w-1/2">
						<_Wrapper>
							<div className="w-full h-full">
								<SVImage
									src={cardImage}
									alt="cardImage"
									className="object-cover object-center w-full h-full rounded-xl"
								/>
							</div>
						</_Wrapper>
					</div>
				</div>
				<div className="flex space-x-2">
					<div className="w-1/2">
						<_Wrapper>
							<div>
								<div className="w-full h-1/3 sm:h-2/3">
									<SVImage
										src={templatesCard}
										alt="templates card"
										className="object-cover object-center w-full h-full rounded-t-xl"
									/>
								</div>
								<div className="w-full h-full p-4 sm:h-1/3 sm:p-8 sm:pt-8">
									<div className="font-semibold text-gray-400 type-subtitle">
										Marketplace
									</div>
									<div className="text-gray-500 type-small">
										Your team will get free access to download our templates.
									</div>
								</div>
							</div>
						</_Wrapper>
					</div>
					<div className="w-1/2">
						<_Wrapper>
							<div className="w-full h-full p-4 sm:mt-8 sm:p-8">
								<div className="font-semibold text-gray-400">SSO Integration</div>
								<div className="text-gray-500 type-small">
									Security and privacy are our number one priority. That's why we
									have multiple SSO certificate integrations.
								</div>
							</div>
						</_Wrapper>
					</div>
				</div>
				<div className="w-full">
					<_Wrapper className="-sm:h-[330px] sm:h-[300px]">
						<div className="w-1/2 h-full p-8">
							<div className="flex flex-col items-center justify-center h-full m-auto">
								<div className="w-full font-semibold text-gray-400 text-start">
									Make
									<br />
									Comments
								</div>
								<div className="w-full text-gray-500 type-small text-start">
									Tag your team on <br /> saves you like.
								</div>
							</div>
						</div>
						<div className="w-1/2">
							<SVImage
								src={commentCard}
								alt="comment card"
								className="object-fill w-full h-full rounded-xl"
							/>
						</div>
					</_Wrapper>
				</div>
				<div className="w-full">
					<_Wrapper className="-sm:h-[330px] sm:h-[300px]">
						<div className="relative flex flex-col w-full">
							<div className="flex flex-col justify-center w-full h-full px-8 -mt-24">
								<div className="w-full text-3xl font-semibold text-center text-gray-400 ">
									Team Feed
								</div>
								<div className="w-full text-center text-gray-500 type-small">
									Create a safe space for your team to share inspiration
								</div>
							</div>
							<div className="absolute bottom-0 w-full h-4/6">
								<SVImage
									src={teamFeedCard}
									alt="team feed card"
									className="object-contain w-full h-full rounded-xl"
								/>
							</div>
							<div className="absolute flex w-full space-x-4 flex-center bottom-10">
								<div className="font-semibold text-gray-400 type-subtitle">you</div>
								<div className="px-4 py-2 font-semibold text-gray-400 rounded-full type-subtitle ring-1 ring-gray-400 ring-opacity-50">
									team
								</div>
							</div>
						</div>
					</_Wrapper>
				</div>
			</div>
		);
	};

	const _GroupCardsLargeContent = () => {
		return (
			<div className="-lg:px-9 -lg:h-[620px] flex h-[600px] w-full max-w-[1600px] justify-center space-x-4 px-28">
				<div className="w-[20%] space-y-4">
					<div className="h-2/5">
						<_Wrapper>
							<div className="flex flex-col w-full h-full p-6">
								<div className="w-full h-1/3">
									<SVImage
										src={avatarCard}
										alt="avatar card"
										className="object-contain w-full h-full rounded-xl"
									/>
								</div>
								<div className="space-y-2 h-2/3 pt-7">
									<div className="font-semibold text-gray-400 type-subtitle">
										Easy <br /> collaboration
									</div>
									<div className="text-gray-500 type-small">
										Keep everyone inspired <br /> with Savee.
									</div>
								</div>
							</div>
						</_Wrapper>
					</div>
					<div className="h-3/5">
						<_Wrapper>
							<div className="flex flex-col w-full h-full">
								<div className="h-full max-h-[200px] w-full">
									<SVImage
										src={templatesCard}
										alt="templates card"
										className="object-cover object-center w-full h-full rounded-t-xl"
									/>
								</div>
								<div className="p-6 space-y-2 -lg:pt-4 h-1/3 pt-7">
									<div className="font-semibold text-gray-400 type-subtitle">
										Marketplace
									</div>
									<div className="text-gray-500 type-small">
										Your team will get free access <br /> to download our
										templates.
									</div>
								</div>
							</div>
						</_Wrapper>
					</div>
				</div>
				<div className="w-[36%] space-y-4">
					<div className="h-3/5">
						<_Wrapper>
							<div className="flex flex-col items-center justify-center w-1/2 h-full p-8 space-y-2 ">
								<div className="w-full font-semibold text-gray-400 type-subtitle">
									Make <br /> Comments
								</div>
								<div className="w-full text-gray-500 type-small">
									Tag your team on <br /> saves you like.
								</div>
							</div>
							<div className="relative w-1/2 h-full">
								<SVImage
									src={commentCard}
									alt="comment card"
									className="absolute right-0 object-fill w-full h-full rounded-r-xl"
								/>
							</div>
						</_Wrapper>
					</div>
					<div className="flex w-full space-x-4 h-2/5">
						<_Wrapper>
							<SVImage
								src={cardImage}
								alt="card"
								className="object-fill w-full h-full rounded-xl"
							/>
						</_Wrapper>
						<_Wrapper>
							<div className="flex flex-col p-8 space-y-10 -md:p-4">
								<div className="flex flex-col space-y-2">
									<div className="font-semibold text-gray-400 type-subtitle">
										SSO Integration
									</div>
									<div className="text-gray-600 type-small">
										Security and privacy are our number one priority. That's why
										we have multiple SSO certificate integrations.
									</div>
								</div>
							</div>
						</_Wrapper>
					</div>
				</div>
				<div className="h-[102.5%] w-2/5">
					<_Wrapper>
						<div className="flex flex-col w-full h-full">
							<div className="w-full px-24 pt-16 space-y-2 text-center">
								<div className="font-semibold text-gray-400 type-subtitle text-4xl2">
									Team Feed
								</div>
								<div className="text-gray-500 type-small">
									Create a safe space for your team to share inspiration
								</div>
							</div>
							<div className="relative w-full h-full">
								<SVImage
									src={teamFeedCard}
									alt="team feed card"
									className="-lg:h-[70%] absolute bottom-0 h-[80%] w-full rounded-xl object-contain"
								/>
								<div className="absolute flex items-center justify-center w-full space-x-4 bottom-20">
									<div className="font-semibold text-gray-400 text-opacity-50 type-subtitle">
										you
									</div>
									<div className="px-4 py-2 font-semibold text-gray-400 rounded-full type-subtitle ring-1 ring-gray-400 ring-opacity-50">
										team
									</div>
								</div>
							</div>
						</div>
					</_Wrapper>
				</div>
			</div>
		);
	};

	const _GroupCardsContainer = () => {
		const viewportName = useViewportName();
		const isSmallNav =
			viewportName === 'small' ||
			(!viewportName && ViewportKind.MOBILE) ||
			viewportName === 'medium' ||
			viewportName === 'medium-portrait';

		return isSmallNav ? <_GroupCardsSmallContent /> : <_GroupCardsLargeContent />;
	};

	return (
		<div className="flex flex-col w-full space-y-20 flex-center">
			<_Title
				title="Built for forward-thinking teams"
				description={
					<>
						Savee let's you create a feed for the entire company to consume inspiration
						<br /> and share work in progress with each other.
					</>
				}
			/>
			<_GroupCardsContainer />
			<div>
				<SVButton
					className="mt-5 w-full max-w-[300px]"
					use={SVButtonUSES.PRIMARY}
					onClick={handleRequestDemo}
				>
					Request a enterprise demo
				</SVButton>
			</div>
		</div>
	);
};

const _NavigatoToPlanButtons = () => {
	const handleClick = useEventCallback(() => {
		scrollToElement(document.getElementById('upgrade-plans') as HTMLElement);
	});

	return (
		<SVButton onClick={handleClick} use={SVButtonUSES.PRIMARY}>
			Choose your plan
		</SVButton>
	);
};

const _SVUpgradePage = () => {
	const [billingCycle, setBillingCycle] = useState<ValueOf<typeof BillingCycle>>(
		BillingCycle.YEARLY,
	);
	const hasExpiredSubscription = Boolean(useAuthSubscriptionIsExpired());

	//do animations
	useEffect(() => {
		const elementsToAnimate: NodeListOf<HTMLImageElement> =
			document.querySelectorAll('.hero-parallax');

		elementsToAnimate.forEach((element) => {
			gsap.to(element, {
				scrollTrigger: {
					scrub: true,
					start: element.dataset.start,
					trigger: element.dataset.start ? element : undefined,
				},
				y: (i, target) => -ScrollTrigger.maxScroll(window) * target.dataset.speed,
				ease: 'none',
			});
		});
	}, []);

	const meta = {
		title: 'Upgrade to Premium',
	};

	let title;

	if (hasExpiredSubscription) {
		title = 'Restart your subscription';
		meta.title = 'Restart your subscription';
	} else {
		title = <>Unleash your creative freedom</>;
	}

	return (
		<div>
			<SVPageMeta {...meta} />
			<div className="relative overflow-hidden">
				<div className="-sm:hidden">
					<SVImage
						id="update-hero-image-2"
						src={heroImage2}
						alt="ladder leaning against the wall"
						data-speed={0.05}
						className="hero-parallax -md:top-[7vw] -md:w-[12vw] absolute right-0 top-[40px] w-40 transition-all xl:w-[10vw]"
					/>
					<SVImage
						id="update-hero-image"
						src={heroImage1}
						alt="ball of basketball"
						data-speed={0.05}
						className="hero-parallax -md:top-[7vw] -md:w-[12vw] absolute top-[40px] w-40 transition-all xl:w-[10vw]"
					/>
					<SVImage
						id="update-hero-image-1"
						src={heroImage}
						alt="clock pointer"
						data-speed={0.2}
						className="hero-parallax -md:top-[20vw] -md:w-[7vw] absolute top-[13vw] w-28 transition-all"
					/>
					<SVImage
						id="update-hero-image-3"
						src={heroImage3}
						alt="woman template"
						data-speed={0.2}
						className="hero-parallax -md:top-[20vw] -md:w-[7vw] absolute right-0 top-[13vw] w-28 transition-all"
					/>
				</div>
				<SVPageHeader>
					<div className="pt-10">
						<SVPageTitle>
							<span className="text-gray-400 type-title -sm:type-huge -sm:text-6xl max-w-max text-balance">
								{title}
							</span>
						</SVPageTitle>
						<SVPageSubtitle>
							<span className="type-pagenav">
								Explore a better experience with unlimited{' '}
								<br className="sm:hidden" />
								saving and browsing.
							</span>
						</SVPageSubtitle>
					</div>
				</SVPageHeader>
				<SVPageMargins>
					<div className="flex-col items-center pb-32 mt-16 -md:pb-24">
						<div className="flex w-full flex-center">
							<SVToggle
								isPressed={billingCycle !== BillingCycle.YEARLY}
								onClick={() =>
									setBillingCycle(
										billingCycle === BillingCycle.YEARLY
											? BillingCycle.MONTHLY
											: BillingCycle.YEARLY,
									)
								}
								use={SVToggle.USES.BILLING_CYCLE}
								size={SVToggle.SIZES.LARGE}
								optionsChildren={
									<div className="flex justify-between w-full -ml-1 space-x-12 flex-center -sm:space-x-12 -md:space-x-14">
										<div
											className={clsx(
												'text-background',
												billingCycle !== BillingCycle.MONTHLY &&
													'text-primary',
											)}
										>
											Monthly
										</div>
										<div
											className={clsx(
												'text-background',
												billingCycle !== BillingCycle.YEARLY &&
													'text-primary',
											)}
										>
											Annual
										</div>
									</div>
								}
							/>
						</div>
						<_Plans billingCycle={billingCycle} />
					</div>
					<_SocialProof />
					<SVInfoSection
						movie={SVUpgradePageMP4}
						title="Pure inspiration, zero distractions."
						description="As an independent platform with no outside investors, we're committed solely to our creative community. "
						descriptionExtraLine="Your support helps us stay true to our mission: pure inspiration without ads or data selling."
						label="Made for you"
						CTAButton={<_NavigatoToPlanButtons />}
						minimalVideoPlayer
					/>
					<div className="items-center justify-center w-full mt-48 -md:mt-32">
						<_Title
							title={
								<>
									Where industry-leading creative teams <br /> find their next big
									idea
								</>
							}
							description="Teams at Apple, Google, Spotify and other leading brands choose us for inspiration"
						/>
						<div>
							<SVBrands.Brands />
						</div>
					</div>
				</SVPageMargins>
				<div className="mt-32 -md:mt-24">
					<_RequestEnterpriseDemo />
				</div>
				<SVFooterImageGallery />
				<SVPageMargins>
					<SVLandingPageFooter className="my-10" />
				</SVPageMargins>
			</div>
		</div>
	);
};

const SVUpgradePage = SVWithBodyClass('theme-dark')(_SVUpgradePage);

export default SVUpgradePage;
