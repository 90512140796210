import {
	ItemFragmentFragment,
	type Board,
	type User,
	type UserFragmentFragment,
} from '@apps/www/src/__generated__/graphql';
import SVFollowButtonContainer from '@apps/www/src/www/containers/SVFollowButtonContainer';
import { preventDefault } from '@pkgs/shared-client/helpers/dom';
import IconCheckSVG from '@pkgs/shared-client/img/icon-check-inlined.svg';
import SVCollaboratorsSVG from '@pkgs/shared-client/img/icon-collaborators-inlined.svg';
import IconFirstSavedSVG from '@pkgs/shared-client/img/icon-first-saved-inlined.svg';
import IconLoadingSVG from '@pkgs/shared-client/img/icon-loading-inlined.svg';
import IconLockSVG from '@pkgs/shared-client/img/icon-lock-inlined.svg';
import IconPlusSVG from '@pkgs/shared-client/img/icon-plus-inlined.svg';
import BoardOwnershipType from '@pkgs/shared/enums/BoardOwnershipType';
import clsx from 'clsx';
import React, { memo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import SVA from './SVA';
import SVAvatar from './SVAvatar';
import SVButton, { SVButtonSIZES, SVButtonUSES } from './SVButton';
import SVFlexSpacer from './SVFlexSpacer';
import SVKeyboardKey, { type KeyboardKeys } from './SVKeyboardKey';
import SVLink from './SVLink';
import SVProBadge from './SVProBadge';
import SVToggle from './SVToggle';

const _Wrapper = ({ className, ...props }: React.PropsWithChildren<{ className?: string }>) => (
	<span
		className={twMerge(
			'font-base text-secondary flex flex-col items-stretch p-2 text-left font-normal',
			className,
		)}
		{...props}
	/>
);

const _BoardItemThumb = ({
	images,
	isSelected,
}: {
	images: ArrayElement<UserFragmentFragment['boards']>['thumbnails'];
	isSelected: boolean;
}) => (
	<span
		className={twMerge(
			'duration-over mr-3 h-8 w-8 flex-shrink-0 rounded-[4px] bg-gray-900 bg-cover bg-center bg-no-repeat',
			isSelected && 'opacity-60',
		)}
		style={
			images?.length > 0
				? {
						backgroundImage: `url(${images[0].image.thumbnail})`,
				  }
				: {}
		}
	></span>
);

const _BoardNewThumb = () => (
	<span className="flex items-center justify-center flex-shrink-0 w-8 h-8 mr-2 transition-opacity ease-out rounded-full bg-primary duration-over text-background">
		<IconPlusSVG className="h-[14px] w-[14px]" />
	</span>
);

const _BoardItem = ({
	board,
	onClick,
	isSelected,
	hasAnyPrivate,
	keepOpenOnClick,
	...props
}: {
	board: ArrayElement<UserFragmentFragment['boards']>;
	onClick: (board: ArrayElement<UserFragmentFragment['boards']>) => void;
	isSelected: boolean;
	hasAnyPrivate: boolean;
	keepOpenOnClick: boolean;
}) => (
	<SVA
		className="relative flex items-center min-w-0 p-2 text-left text-gray-500 transition-all duration-200 rounded-lg hover:text-primary group/boards-dropdown-item shrink-0 hover:bg-gray-700"
		Component="button"
		onClick={(event) => {
			if (keepOpenOnClick) {
				event.preventDefault();
			}
			onClick(board);
		}}
		title={`${isSelected ? 'Remove from' : 'Add to'} ${board.name}`}
		{...props}
	>
		<_BoardItemThumb images={board.thumbnails} isSelected={isSelected} />
		<span className="mr-3 min-w-[120px] truncate">{board.name}</span>
		<SVFlexSpacer />
		<div className="min-w-[85px]">
		<div className="flex items-center justify-end space-x-2 transition-all opacity-100 duration-over group-hover/boards-dropdown-item:hidden group-hover/boards-dropdown-item:opacity-0">
			{board.collaborators.length + board.invites.length > 1 && (
				<div className="text-primary">
					<SVCollaboratorsSVG />
				</div>
			)}
			{hasAnyPrivate ? (
				<span className="flex-shrink-0">
					{board.isPrivate && (
						<span className="relative flex items-center justify-center w-6 h-6 transition-opacity bg-opacity-50 rounded-full text-primary duration-over bg-background backdrop-blur-lg">
							<IconLockSVG className="w-3 h-3" />
						</span>
					)}
				</span>
			) : null}
			{isSelected && (
				<div className="flex-shrink-0 text-primary">
					<span className="relative flex items-center justify-center w-6 h-6 transition-opacity rounded-full text-background duration-over bg-primary backdrop-blur-lg">
						<IconCheckSVG className="w-3 h-3" />
					</span>
				</div>
			)}
		</div>
		<div className="items-center justify-end hidden space-x-2 transition-all opacity-0 duration-over group-hover/boards-dropdown-item:flex group-hover/boards-dropdown-item:opacity-100">
			<SVButton
				size={SVButtonSIZES.SMALL}
				use={SVButtonUSES.PRIMARY}
				className="px-3 py-2 pointer-events-none"
			>
				{isSelected ? 'Remove' : 'Save'}
			</SVButton>
		</div>
		</div>
	</SVA>
);

const _Boards = ({
	boards,
	selectedIDs,
	hasAnyPrivate,
	keepOpenOnClick,
	onBoardClick,
}: {
	boards: UserFragmentFragment['boards'];
	selectedIDs: Board['_id'][] | null | undefined;
	hasAnyPrivate: boolean;
	keepOpenOnClick: boolean;
	onBoardClick: (board: ArrayElement<UserFragmentFragment['boards']>) => void;
}) => (
	<>
		{boards.map((board) => (
			<_BoardItem
				key={board._id}
				board={board}
				isSelected={Boolean(selectedIDs && selectedIDs.includes(board._id))}
				hasAnyPrivate={hasAnyPrivate}
				keepOpenOnClick={keepOpenOnClick}
				onClick={onBoardClick}
			/>
		))}
	</>
);

const _SectionLabel = ({ label }: { label: string }) => (
	<span className="my-3 ml-2 text-sm text-gray-200">{label}</span>
);

const SVBoardsDropdownContent = ({
	boards,
	onNewBoard,
	onBoardClick,
	selectedIDs,
	keepOpenOnClick = false,
}: {
	boards: UserFragmentFragment['boards'];
	onNewBoard: React.MouseEventHandler | null;
	onBoardClick: (board: ArrayElement<UserFragmentFragment['boards']>) => void;
	selectedIDs?: Board['_id'][];
	keepOpenOnClick?: boolean;
}) => {
	// Save board.lastItemAddedOrder state on a map so the order don't change whenever items get added to a board
	// when keepOpenOnClick is `true` (multi select)
	const [lastItemAddedOrderMap] = useState<Record<string, number | null | undefined>>(() => {
		return boards.reduce((sum: Record<string, number | null | undefined>, board) => {
			sum[board._id] = board.lastItemAddedOrder;

			return sum;
		}, {});
	});

	const lastUsedBoards =
		boards.length > 4
			? [...boards]
					.sort((a, b) => {
						const aOrder = lastItemAddedOrderMap[a._id] || 0;
						const bOrder = lastItemAddedOrderMap[b._id] || 0;

						return bOrder - aOrder;
					})
					.slice(0, 3)
			: [];

	// Should show all boards regardless of the `lastUsedBoards` list
	// const remainingBoards = boards.filter((board) => !lastUsedBoards.includes(board));
	const remainingBoards = boards;

	const userBoards = remainingBoards.filter(
		(board) => board.ownershipType === BoardOwnershipType.USER,
	);
	const teamBoards = remainingBoards.filter(
		(board) => board.ownershipType === BoardOwnershipType.TEAM,
	);

	const hasAnyPrivate = boards.some((board) => board.isPrivate);

	return (
		<>
			<_Wrapper className="theme-dark max-w-[360px] min-w-[320px] overflow-y-auto overscroll-contain whitespace-nowrap bg-gray-800">
				{lastUsedBoards.length > 0 ? <_SectionLabel label="Last used" /> : null}
				<_Boards
					boards={lastUsedBoards}
					selectedIDs={selectedIDs}
					hasAnyPrivate={hasAnyPrivate}
					keepOpenOnClick={keepOpenOnClick}
					onBoardClick={onBoardClick}
				/>
				{userBoards.length > 0 && lastUsedBoards.length > 0 ? (
					<_SectionLabel label="My boards" />
				) : null}
				<_Boards
					boards={userBoards}
					selectedIDs={selectedIDs}
					hasAnyPrivate={hasAnyPrivate}
					keepOpenOnClick={keepOpenOnClick}
					onBoardClick={onBoardClick}
				/>
				{teamBoards.length > 0 ? <_SectionLabel label="Team boards" /> : null}
				<_Boards
					boards={teamBoards}
					selectedIDs={selectedIDs}
					hasAnyPrivate={hasAnyPrivate}
					keepOpenOnClick={keepOpenOnClick}
					onBoardClick={onBoardClick}
				/>
			</_Wrapper>
			{onNewBoard && (
				<>
					<span className="flex flex-shrink-0 p-3 font-normal bg-gray-800 border-t border-white theme-dark whitespace-nowrap border-opacity-20">
						<SVA
							className="flex items-center flex-grow min-w-0 text-left"
							key="add"
							Component="button"
							onClick={onNewBoard}
						>
							<_BoardNewThumb />
							<span className="mr-3 text-base">New board</span>
						</SVA>
					</span>
				</>
			)}
		</>
	);
};

type UserFragment = {
	_id: User['_id'];
	name: User['name'];
	url: User['url'];
	avatarURL: User['avatarURL'];
	isPro: User['isPro'];
	username: User['username'];
	canFollow: User['canFollow'];
	isFollowing: User['isFollowing'];
	isFollowingBack: User['isFollowingBack'];
};

type LikeUser = UserFragment | { user: UserFragment };

const UserItem = memo(({ item, isAuthor }: { item: LikeUser; isAuthor?: boolean }) => {
	const user = 'user' in item ? item.user : item;

	return (
		<li className="flex items-center space-x-2 leading-none">
			<SVA
				className="flex items-center min-w-0 space-x-2"
				Component={SVLink}
				to={user.url}
				title={user.name}
			>
				<SVAvatar className="w-8 h-8" src={user.avatarURL} />
				<span className="flex flex-col min-w-0 space-y-1">
					<span className="flex items-center min-w-0">
						<span className="truncate">{user.name}</span>
						{user.isPro && (
							<SVProBadge className="transition-opacity ease-out duration-over group-hover:opacity-80" />
						)}
					</span>
					<span className="flex items-center space-x-2 transition-colors ease-out type-label text-muted duration-over group-hover:text-gray-600">
						<span className="truncate">{`@${user.username}`}</span>
						{isAuthor && (
							<span className="flex items-center space-x-1">
								<IconFirstSavedSVG className="text-brand" />
								<span>First saved</span>
							</span>
						)}
					</span>
				</span>
			</SVA>
			<SVFlexSpacer />
			<SVFollowButtonContainer
				userID={user._id}
				canFollow={user.canFollow}
				isFollowing={user.isFollowing}
				isFollowingBack={user.isFollowingBack}
				size={SVButtonSIZES.TINY}
			/>
		</li>
	);
});

const SVUsersDropdownContent = ({
	users,
	firstIsAuthor = false,
	...props
}: {
	users: LikeUser[];
	firstIsAuthor?: boolean;
}) => (
	<_Wrapper className="space-y-4 whitespace-nowrap" {...props}>
		{users.map((item, index) => {
			const user = 'user' in item ? item.user : item;

			return (
				<UserItem
					key={user.username}
					item={item}
					isAuthor={!!firstIsAuthor && index === 0}
				/>
			);
		})}
	</_Wrapper>
);

const LINK_USES = {
	DEFAULT: 'default',
	HIGHLIGHT: 'highlight',
} as const;

const _LinkItemWrapper = React.forwardRef<
	HTMLDivElement,
	React.PropsWithChildren<{
		className?: string;
		disableHover?: boolean;
		isOnMenu?: boolean;
	}>
>(({ className, children, disableHover = false, isOnMenu = false }, forwardedRef) => (
	<div
		ref={forwardedRef}
		className={twMerge(
			clsx(
				'relative flex min-w-0 max-w-[320px] items-center',
				!disableHover &&
					'hover:text-primary duration-over rounded-md px-2 py-1.5 transition-all hover:bg-gray-700',
				isOnMenu && 'px-1 py-1',
			),
			className,
		)}
	>
		{children}
	</div>
));

type LinkItemPropsBase = React.PropsWithChildren<{
	className?: string;
	isLoading?: boolean;
	use?: ValueOf<typeof LINK_USES>;
	isSelected?: boolean;
	title?: React.HTMLProps<HTMLButtonElement>['title'];
	disableHover?: boolean;
	isOnMenu?: boolean;
}>;

type LinkItemButtonProps = LinkItemPropsBase & {
	Component: 'button';
	onClick: (event: React.UIEvent) => void;
	keys?: KeyboardKeys;
};

type LinkItemLinkProps = LinkItemPropsBase &
	(
		| ({
				Component?: typeof SVLink;
		  } & Pick<React.ComponentPropsWithoutRef<typeof SVLink>, 'to' | 'onClick' | 'keys'>)
		| ({
				Component: 'a';
		  } & Pick<React.HTMLProps<HTMLAnchorElement>, 'href' | 'target' | 'rel'>)
	);

const _LinkItemContent = ({
	className,
	isLoading,
	use,
	Component = SVLink,
	isSelected,
	children,
	disableHover,
	...props
}: LinkItemButtonProps | LinkItemLinkProps) => {
	return (
		// @ts-expect-error
		<Component
			className={twMerge(
				clsx(
					'text-primary hover:text-muted group relative flex flex-grow cursor-pointer items-center py-1 transition-colors',
					isLoading && 'opacity-40',
					use === LINK_USES.HIGHLIGHT && 'font-semibold',
					isSelected === true && '',
					isSelected === false && 'text-muted hover:text-primary',
					!disableHover && 'hover:text-primary py-0',
				),
				className,
			)}
			{...props}
		>
			{Component === 'button' && 'keys' in props && 'onClick' in props && (
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				<SVKeyboardKey keys={props.keys!} onTrigger={props.onClick!} />
			)}
			{isSelected === true ? (
				<IconCheckSVG className="text-primary absolute ml-[-9px] h-[9px] w-[9px] flex-shrink-0" />
			) : null}
			{children}
		</Component>
	);
};

const _LinkItemLoading = () => (
	<div className="absolute inset-0 pointer-events-none flex-center" role="progressbar">
		<IconLoadingSVG className="scale-75" />
	</div>
);

const LinkSeparator = () => <div className="my-3 border-b border-separator bg-opacity-90" />;

const LinkItemLabel = ({ children }: OnlyChildrenProps) => (
	<span className="type-label text-muted">{children}</span>
);

const linkItemLinkDefaultProps = {
	Component: SVLink,
};

const LinkItemLink = ({ children, isLoading, use, disableHover, ...props }: LinkItemLinkProps) => (
	<_LinkItemWrapper
		className={clsx(isLoading && 'pointer-events-none')}
		disableHover={disableHover}
	>
		{isLoading && <_LinkItemLoading />}
		<_LinkItemContent isLoading={isLoading} use={use} disableHover={disableHover} {...props}>
			{children}
		</_LinkItemContent>
	</_LinkItemWrapper>
);

LinkItemLink.defaultProps = linkItemLinkDefaultProps;

const LinkItem = React.forwardRef<HTMLDivElement, Omit<LinkItemButtonProps, 'Component'>>(
	({ children, isLoading, use, disableHover, isOnMenu = false, ...props }, forwardedRef) => (
		<_LinkItemWrapper
			ref={forwardedRef}
			className={clsx(isLoading && 'pointer-events-none')}
			disableHover={disableHover}
			isOnMenu={isOnMenu}
		>
			{isLoading && <_LinkItemLoading />}
			<_LinkItemContent
				Component="button"
				isLoading={isLoading}
				disableHover={disableHover}
				use={use}
				{...props}
			>
				{children}
			</_LinkItemContent>
		</_LinkItemWrapper>
	),
);

const LinkItemPreventClose = React.forwardRef<
	HTMLDivElement,
	Omit<LinkItemButtonProps, 'Component'>
>(({ onClick, ...props }, forwardedRef) => (
	<LinkItem ref={forwardedRef} onClick={preventDefault(onClick)} {...props} />
));

const LinkItemMain = ({
	label,
	children,
	use,
	disableHover,
	...props
}: LinkItemLinkProps & { label: string }) => (
	<_LinkItemWrapper disableHover={disableHover}>
		<_LinkItemContent
			className="flex-col items-start min-w-0 space-y-1"
			use={use}
			disableHover={disableHover}
			{...props}
		>
			<span className="max-w-full font-semibold truncate">{children}</span>
			{label && <span className="type-label text-muted">{label}</span>}
		</_LinkItemContent>
	</_LinkItemWrapper>
);

const LinkItemToggle = ({
	children,
	onClick,
	isPressed,
	isLoading,
	disableHover,
	...props
}: Omit<LinkItemButtonProps, 'Component'> & { isPressed: boolean }) => (
	<_LinkItemWrapper disableHover={disableHover}>
		{isLoading && <_LinkItemLoading />}
		<SVToggle
			className="relative"
			size={SVToggle.SIZES.SMALL}
			isPressed={isPressed}
			onClick={preventDefault(onClick)}
		/>
		<_LinkItemContent
			className="ml-2"
			Component="button"
			disableHover={disableHover}
			onClick={preventDefault(onClick)}
			{...props}
		>
			<span>{children}</span>
		</_LinkItemContent>
	</_LinkItemWrapper>
);

const SVLinksDropdownContent = ({ children }: OnlyChildrenProps) => (
	<_Wrapper className="min-w-[130px] whitespace-nowrap">{children}</_Wrapper>
);

SVLinksDropdownContent.ItemLink = LinkItemLink;
SVLinksDropdownContent.Item = LinkItem;
SVLinksDropdownContent.Item_USES = LINK_USES;
SVLinksDropdownContent.ItemLabel = LinkItemLabel;
SVLinksDropdownContent.ItemPreventClose = LinkItemPreventClose;
SVLinksDropdownContent.ItemLinkMain = LinkItemMain;
SVLinksDropdownContent.ItemToggle = LinkItemToggle;
SVLinksDropdownContent.Separator = LinkSeparator;

const SVDropdownContent = {
	Boards: SVBoardsDropdownContent,
	Users: SVUsersDropdownContent,
	Links: SVLinksDropdownContent,
};

export default SVDropdownContent;
