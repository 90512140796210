import { useApolloClient } from '@apollo/client';
import useAuthSettings from '@apps/www/src/www/hooks/useAuthSettings';
import useNotificationsLimit from '@apps/www/src/www/hooks/useNotificationsLimit';
import ClearUnreadNotificationsCountMutation from '@apps/www/src/www/queries/ClearUnreadNotificationsCountMutation';
import NotificationsQuery from '@apps/www/src/www/queries/NotificationsQuery';
import { openNotifications } from '@apps/www/src/www/reducers/ui';
import SVDropdownContent from '@pkgs/shared-client/components/SVDropdownContent';
import SVNotificationIcon from '@pkgs/shared-client/components/SVNotificationIcon';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import { useDispatch } from 'react-redux';

const SVNotificationsIconContainer = ({ showLabel = false, iconClassName, isSmallNav}: { showLabel?: boolean, iconClassName?: string, isSmallNav?: boolean }) => {
	const dispatch = useDispatch();
	const client = useApolloClient();
	const limit = useNotificationsLimit();
	const authSettings = useAuthSettings(['unreadNotificationsCount']);

	const handleClick = useEventCallback(() => {
		if (authSettings && authSettings.unreadNotificationsCount > 0) {
			client.mutate({
				mutation: ClearUnreadNotificationsCountMutation,
			});
		}

		dispatch(openNotifications());
	});

	const handleMouseOver = useEventCallback(() => {
		if (authSettings && authSettings.unreadNotificationsCount > 0) {
			// Prefetch notifications on mouse hover
			client.query({
				query: NotificationsQuery,
				variables: {
					limit,
				},
			});
		}
	});

	if (!authSettings) {
		return null;
	}

	return (
		<SVDropdownContent.Links.Item onClick={handleClick} disableHover>
			<SVNotificationIcon
				count={authSettings.unreadNotificationsCount}
				onClick={handleClick}
				onMouseOver={handleMouseOver}
				isOutsideTheMenu={!showLabel}
				iconClassName={iconClassName}
				isSmallNav={isSmallNav}
			/>
			{showLabel && <div className="ml-3">Notifications</div>}
		</SVDropdownContent.Links.Item>
	);
};

export default SVNotificationsIconContainer;
