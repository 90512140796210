import SVUserHeader from '@apps/www/src/www/components/SVUserHeader';
import useUserAndBoardPageQuery from '@apps/www/src/www/hooks/useUserAndBoardPageQuery';
import SVPageMeta from './SVPageMeta';
import { closeNav, openGridSettings, openGridUpload, openNav } from '@apps/www/src/www/reducers/ui';
import { useDispatch, useSelector } from 'react-redux';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import useAuthSettings from '@apps/www/src/www/hooks/useAuthSettings';
import useAuthUser from '@apps/www/src/www/hooks/useAuthUser';

const SVUserHeaderContainer = () => {
	const { isOwner, user } = useUserAndBoardPageQuery();
	const dispatch = useDispatch();
	const authSettings = useAuthSettings(['email', 'unreadNotificationsCount']);
	const authUser = useAuthUser(['avatarURL', 'url', 'name', 'itemsCount']);
	
	const handleOpenNav = useEventCallback(() => dispatch(openNav()));

	if (!user) {
		return null;
	}

	const meta = {
		title: user.name,
		image: user.ogImage,
	};

	return (
		<>
			<SVPageMeta {...meta} />
			<SVUserHeader asOwner={isOwner} user={user} unreadNotificationsCount={authSettings?.unreadNotificationsCount || 0} onOpenNav={handleOpenNav} userURL={authUser?.url || ''} />
		</>
	);
};

export default SVUserHeaderContainer;
