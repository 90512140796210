import SVImage from '@pkgs/shared-client/components/SVImage';
import SVCourseFooterImages from '@pkgs/shared-client/img/course/course-footer-images.png';

const SVFooterImageGallery = (): JSX.Element => (
	<div className="-sm:mt-24 -md:mt-32 relative mt-44 flex min-h-[180px] w-full justify-center pb-[20%]">
		<SVImage
			src={SVCourseFooterImages}
			className="absolute h-full w-auto max-w-none"
			alt="Course footer images"
		/>
	</div>
);

export default SVFooterImageGallery;
